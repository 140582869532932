import React from 'react';

export default() => {
  return(
    <div className="flex items-center px-2 text-primary-blue">
      <svg className="spinner icon-4" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle className="spinner__path" cx="12" cy="12" r="10" fill="none"></circle>
      </svg> &nbsp;&nbsp; Loading...
    </div>
  );
}

import * as React from 'react';

import tableau from 'tableau-api';


class SiteMap extends React.Component {
  componentDidMount() {
    this.initViz()
  }

  initViz() {
    const vizURL = process.env.REACT_APP_TABLEAU_URL || "https://public.tableau.com/views/SSSLD_App_Map_Hist/NYCContractedProgramSites?:display_count=y&:origin=viz_share_link";
    const vizContainer = this.vizContainer;
    return new window.tableau.Viz(vizContainer, vizURL)  
  }


  render() {
    return (
      <div className="mt-3" ref={(div) => { this.vizContainer = div }}>
      </div>
    )
  }
}

export default SiteMap;

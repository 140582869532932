import React from 'react';

import Logo from '../../svg/icon-logo-nyco-secondary.svg';

export default(props) => {
  return (
    <header className="o-header">
      <div className="logo-padding o-header__banner-main color-primary-button screen-tablet-header">
        <button aria-controls="js-navigation" aria-pressed="false" className="text-xl o-header__main-toggle" data-js="toggle" onClick={()=>props.toggleMenu()}>Menu</button>
        <img style={{width: "150px", height: "auto"}} src={Logo} alt="NYC Opportunity"/>
      </div>
      <div className="o-header__banner-secondary color-dark-background"></div>
    </header>
  )
}
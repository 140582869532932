import React, { Component } from "react";

import { Link } from "react-router-dom";

import Tooltip from '../tooltip';
import ContractQuestions from './contractQuestions';
import GeneralQuestions from './generalQuestions';
import ServiceQuestions from './serviceQuestions';
import CatchmentQuestions from './catchmentQuestions';
import FixedAddressQuestions from './fixedAddressQuestions';
import { siteInputClasses } from "./utils";

import Loading from "../loading";
import { api } from "../../api";
import { titleCase } from "../../utils"

const catchmentZoneBorough = require("../../../constants/catchment_zone_borough.json");
const catchmentZoneCommunitDistrict = require("../../../constants/catchment_zone_community_district.json");
const catchmentZoneZipCode = require("../../../constants/catchment_zone_zip_code.json");
const catchmentZoneEntireCity = require("../../../constants/catchment_zone_entire_city.json");
const languageTaxonomy = require("../../../constants/language_taxonomy.json");
const serviceTaxonomy = require("../../../constants/service_taxonomy.json");
const populationTaxonomy = require("../../../constants/population_taxonomy.json");
const serviceSettingTaxonomy = require("../../../constants/service_setting_taxonomy.json");
const bpCategory = require("../../../constants/bp_category.json");
const bpPopulation = require("../../../constants/bp_population.json");

const getFrontendLabel = (frontendOptions, dbOpt) => frontendOptions.find(frontendOpt => frontendOpt.toLowerCase() === (dbOpt && dbOpt.toLowerCase())) || dbOpt

const matchOptions = (frontendOptions, dbOptions) => {
  return dbOptions.map(o => ({label: getFrontendLabel(frontendOptions, o), value: o})) || [];
}

const matchCatchmentZoneOptions = (dbCatchmentType, dbCatchmentZone) => {
  if (!dbCatchmentType) {
    return [];
  }
  switch(dbCatchmentType.trim().toLowerCase()) {
    case "entire city":
      return dbCatchmentZone.map(o => ({label: getFrontendLabel(catchmentZoneEntireCity, o), value: o})) || [];
    case "borough":
      return dbCatchmentZone.map(o => ({label: getFrontendLabel(catchmentZoneBorough, o), value: o})) || [];
    case "community district":
      return dbCatchmentZone.map(o => ({label: getFrontendLabel(catchmentZoneCommunitDistrict, o), value: o})) || [];
    case "zip code":
      return dbCatchmentZone.map(o => ({label: getFrontendLabel(catchmentZoneZipCode, o), value: o})) || [];
    default:
      return dbCatchmentZone.map(o => ({label: o, value: o})) || [];
  }
}

export default class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_id: this.props.location.state.site.site_id,
      accepts_dropins: false,
      active_flag: true,
      address_1: "",
      address_2: "",
      borough: "",
      bbl: "",
      bin: "",
      capacity: null,
      capacity_units: "",
      census_tract: "",
      city: "",
      community_district: "",
      contract_number: this.props.location.state.contract.number,
      council_district: "",
      fixed_address: true,
      geocodeAutocomplete: "",
      geocodeInput: "",
      latitude: "",
      longitude: "",
      state_assembly_district: "",
      state_senate_district: "",
      newSite: this.props.location.state.newSite,
      notes: "",
      nta: "",
      serves_food: false,
      site_budget: "",
      site_name: "",
      state: "",
      suppress_location: false,
      zip: "",
      bp_category: [],
      bp_population: [],
      language_taxonomy: [],
      population_taxonomy: [],
      service_setting_taxonomy: [],
      service_taxonomy: [],
      catchment_zone: "",
      catchment_type: "",
      catchment_intersection_1: "",
      catchment_intersection_2: "",
      catchment_description: "",
      program: this.props.location.state.program,
      provider: this.props.location.state.provider,
      formErrors: [],
      contract_fiscal_year: this.props.location.state.contract.fiscal_year,
      contract_start: this.props.location.state.contract.start,
      contract_end: this.props.location.state.contract.end,
    };
  }

  componentDidMount() {
    if (!this.props.location.state.newSite) {
      this.setState({
        newSite: this.props.location.state.newSite,
        ...this.transformSiteFromDb(this.props.location.state.site)
      });
    }
  }

  handleChangeMulti(name, event) {
    if (name === "geocodeAutocomplete") {
      this.setState({
        address_1: event.name,
        zip: event.postalcode,
        city: event.borough,
        borough: event.borough,
        geocodeAutocomplete: event,
        geocodeInput: event.label
      });
      return event.label;
    }
    if (name === "geocodeInput") {
      this.setState({ [name]: event });
      return event;
    }
    this.setState({
      [name]: event
    }, ()=> this.handleValidation());
  }

  handleChange = name => event => {
    const target = event.target;
    const value = ["checkbox", "radio"].includes(target.type)
      ? target.checked
      : target.value;
    this.setState({ [name]: value }, ()=> this.handleValidation());
  };

  handleValidation = () => {
    var requiredFields = ['site_name', this.state.fixed_address ? 'address_1' : 'catchment_type', this.state.catchment_type && this.state.catchment_type.toLowerCase() === "other" ? 'catchment_description' : !this.state.fixed_address ? 'catchment_zone' : ''].filter(n=>n)
    var missingFields = []
    requiredFields.forEach( f => {if (!this.state[f] || this.state[f].length < 1) missingFields.push(f)} );
    this.setState({formErrors: missingFields})
    if (missingFields.length >= 1) {
      return false
    }
    return true
  }

  handleSubmit = event => {
    event.preventDefault();
    this.handleValidation()
    if (this.handleValidation()) {
      var getTaxonomyValues = data => data ? data.map(m => m.value) : [];
      const taxonomies = {
        language_taxonomy: getTaxonomyValues(this.state.language_taxonomy),
        service_taxonomy: getTaxonomyValues(this.state.service_taxonomy),
        population_taxonomy: getTaxonomyValues(this.state.population_taxonomy),
        service_setting_taxonomy: getTaxonomyValues(this.state.service_setting_taxonomy),
        bp_category: getTaxonomyValues(this.state.bp_category),
        bp_population: getTaxonomyValues(this.state.bp_population),
        catchment_zone: getTaxonomyValues(this.state.catchment_zone)
      };
      const identifyingInfo = this.state.newSite
        ? {
            contract_id: this.state.provider.contract_id,
            provider_id: this.state.provider.provider_id,
            program_id: this.state.program.program_id
          }
        : {
            site_id: this.state.site_id
          };
      const endpoint = this.state.newSite ? "createsite" : "site";
      const method = "POST";
      const body = JSON.stringify({
        site: {
          suppress_address: this.state.suppress_location ? true : false,
          active_flag: this.state.active_flag ? true : false,
          site_name: this.state.site_name,
          site_budget: this.state.site_budget || undefined,
          notes: this.state.notes || undefined,
          accepts_dropins: this.state.accepts_dropins ? true : false,
          capacity: this.state.capacity || undefined,
          capacity_units:
            (this.state.capacity_units &&
              this.state.capacity_units.toLowerCase()) ||
            undefined,
          serves_food: this.state.serves_food ? true : false,
          fixed_address: this.state.fixed_address ? true : false,
          address_1: this.state.fixed_address ? this.state.address_1 || undefined : undefined,
          address_2: this.state.fixed_address ? this.state.address_2 || undefined : undefined,
          zip: this.state.fixed_address ? this.state.zip || undefined : undefined,
          catchment_type: !this.state.fixed_address ? this.state.catchment_type.toLowerCase() || undefined : undefined,
          catchment_intersection_1:
            !this.state.fixed_address ? this.state.catchment_intersection_1 || undefined : undefined,
          catchment_intersection_2:
            !this.state.fixed_address ? this.state.catchment_intersection_2 || undefined : undefined,
          catchment_description: !this.state.fixed_address ? this.state.catchment_description || undefined : undefined,
          ...taxonomies,
          ...identifyingInfo
        }
      });
      this.setState({
        loading: true
      });
      api(this.props.unauthorize, endpoint, method, body).then(res => {
        if (!res.status) {
          const action = this.state.newSite ? "create" : "update";
          return this.setState({
            error: `Unable to ${action} site`,
            loading: false
          });
        } else {
          this.setState(
            {
              error: null,
              loading: false,
              newSite: false,
              ...this.transformSiteFromDb(res.response.site)
            },
            () => {
              this.props.updateContractsAndSites()
              this.handleValidation()
              this.props.history.replace({state: Object.assign(this.props.location.state, {site: res.response.site})})
            }
          );
        }
      });
    } else {
      this.setState({error: "Please complete all required fields."})
    }
  };

  transformSiteFromDb(site) {
    return {
      site_id: site.site_id,
      accepts_dropins: site.accepts_dropins,
      active_flag: site.active_flag ? true : false,
      address_1: site.address_1 || "",
      address_2: site.address_2 || "",
      borough: site.borough || "",
      bbl: site.bbl || "",
      bin: site.bin || "",
      capacity: site.capacity,
      capacity_units: site.capacity_units,
      census_tract: site.census_tract || "",
      city: site.city || "",
      community_district: site.community_district || "",
      council_district: site.council_district || "",
      fixed_address: site.fixed_address ? true : false,
      geocodeInput: site.address_1
        ? `${site.address_1 && site.address_1.toUpperCase()}, ${
            site.borough && titleCase(site.borough)
          }, New York, NY, USA`
        : "",
      latitude: site.latitude || "",
      longitude: site.longitude || "",
      state_senate_district: site.state_senate_district || "",
      state_assembly_district: site.state_assembly_district || "",
      notes: site.notes,
      nta: site.nta || "",
      serves_food: site.serves_food,
      site_budget: site.site_budget,
      site_name: site.site_name,
      state: site.state || "",
      suppress_location: site.suppress_address,
      zip: site.zip || "",
      bp_category: matchOptions(bpCategory, site.bp_category),
      bp_population: matchOptions(bpPopulation, site.bp_population),
      language_taxonomy: matchOptions(languageTaxonomy, site.language_taxonomy),
      population_taxonomy: matchOptions(populationTaxonomy, site.population_taxonomy),
      service_setting_taxonomy: matchOptions(serviceSettingTaxonomy, site.service_setting_taxonomy),
      service_taxonomy: matchOptions(serviceTaxonomy, site.service_taxonomy),
      catchment_zone: matchCatchmentZoneOptions(site.catchment_type, site.catchment_zone),
      catchment_type: site.catchment_type || "",
      catchment_intersection_1: site.catchment_intersection_1 || "",
      catchment_intersection_2: site.catchment_intersection_2 || "",
      catchment_description: site.catchment_description || "",
    }
  }

  errorMessage() {
    if (this.state.error) {
      return (
        <div
          style={{ minWidth: "800px" }}
          className="w-1/2 text-center p-2 mt-5 mb-2 bg-primary-red-30t border border-primary-red animated fadeInUp"
        >
          {this.state.error}
        </div>
      );
    }
  }

  handleCatchmentTypeChange(event) {
    this.handleChange("catchment_type")(event);
    this.setState({ catchment_zone: [] });
  }

  render() {
    const siteName = this.state.site_name
      ? this.state.site_name
      : this.state.address_1 || this.state.catchment_zone
      ? `Unnamed Site`
      : "New Site";
    return (
      <form className="m-4 ml-1">
        <h3 className="font-bold mb-4">{siteName}</h3>
        <div>
          <label className="text-xl">Program: </label>
          <Link
            className="underline text-xl"
            to={{
              pathname: "/program",
              state: {
                program: this.state.program
              }
            }}
          >
            {this.state.program.program_name}
          </Link>
        </div>
        <div>
          <label className="text-xl">Provider: </label>
          <Link
            className="underline text-xl"
            to={{
              pathname: "/provider",
              state: {
                provider: this.state.provider
              }
            }}
          >
            {this.state.provider.provider_name}
          </Link>
        </div>

        <div className="mt-3">
          <a name={"general"} href={"#general"}>
            {" "}
          </a>
          <label className="text-xl">Contract:</label>
          <div
            className="mt-1 w-1/2 color-mid-background border border-primary-blue-30t p-3"
            style={{ minWidth: "800px" }}
          >
            <ContractQuestions
              {...this.state}
            />
          </div>
        </div>

        <div className="mt-3">
          <a name={"general"} href={"#general"}>
            {" "}
          </a>
          <label className="text-xl">General:</label>
          <div
            className="mt-1 w-1/2 color-mid-background border border-primary-blue-30t p-3"
            style={{ minWidth: "800px" }}
          >
            <GeneralQuestions
              handleChange={this.handleChange.bind(this)}
              {...this.state}
            />
          </div>
        </div>

        <div className="mt-3">
          <a name={"location"} href={"#location"}>
            {" "}
          </a>
          <label className="text-xl">Location:</label>
          <div
            className="mt-1 text-sm w-1/2 color-mid-background border border-primary-blue-30t p-3"
            style={{ minWidth: "800px" }}
          >
            <div className="w-full">
              <Tooltip
                text="Fixed address signifies that the program is delivered at a fixed address, for example, an after school program in a school building. Catchment signifies that the program is delivered across an area, for example, early intervention services delivered in clients' homes in a particular community district or borough."
                child="Location Type: "
                required={true}
              />
              <div className="mt-1">
                <fieldset>
                  <label className="radio w-1/2 pr-1 font-normal">
                    <input
                      type="radio"
                      name="location"
                      checked={this.state.fixed_address ? true : false}
                      onChange={() => this.setState({ fixed_address: true }, () => this.handleValidation())}
                      disabled={!this.state.active_flag}
                    />
                    <span className={`radio__label ${siteInputClasses(this.state)}`}>Fixed Address</span>
                  </label>
                  <label className="radio w-1/2 font-normal">
                    <input
                      type="radio"
                      name="location"
                      checked={this.state.fixed_address ? false : true}
                      onChange={() => this.setState({ fixed_address: false }, () => this.handleValidation())}
                      disabled={!this.state.active_flag}
                    />
                    <span className={`radio__label ${siteInputClasses(this.state)}`}>Catchment</span>
                  </label>
                </fieldset>
              </div>

              <hr />

              <div className="mt-3">
                <fieldset>
                  <label className="checkbox font-normal">
                    <input
                      id="suppress_address"
                      type="checkbox"
                      checked={this.state.suppress_location}
                      onChange={this.handleChange("suppress_location")}
                      disabled={!this.state.active_flag}
                    />
                    <span className={`checkbox__label ${siteInputClasses(this.state)}`}>
                      <Tooltip
                        text="Check this box to hide address or catchment zone from public-facing communication or data sources."
                        child="Suppress Address?"
                        inline={true}
                      />
                    </span>
                  </label>
                </fieldset>
              </div>
            </div>
            {this.state.fixed_address ? (
              <FixedAddressQuestions
                handleChange={this.handleChange.bind(this)}
                handleChangeMulti={this.handleChangeMulti.bind(this)}
                {...this.state}
              />
            ) : (
              <CatchmentQuestions
                handleChange={this.handleChange.bind(this)}
                handleChangeMulti={this.handleChangeMulti.bind(this)}
                handleCatchmentTypeChange={this.handleCatchmentTypeChange.bind(
                  this
                )}
                {...this.state}
              />
            )}
          </div>
        </div>

        <div className="mt-3">
          <a name={"service"} href={"#service"}>
            {" "}
          </a>
          <label className="text-xl">Service:</label>
          <div
            className="text-sm mt-1 w-1/2 color-mid-background border border-primary-blue-30t p-3"
            style={{ minWidth: "800px" }}
          >
            <ServiceQuestions
              handleChange={this.handleChange.bind(this)}
              handleChangeMulti={this.handleChangeMulti.bind(this)}
              {...this.state}
            />
          </div>
        </div>

        {this.errorMessage()}

        <div className="mt-3 inline-flex">
          <button
            className="py-2 px-4 outline-none btn color-primary-button"
            type="submit"
            onClick={event => this.handleSubmit(event)}
          >
            {this.state.newSite ? "Create" : "Update"}
          </button>
          {this.state.loading && <Loading />}
        </div>
      </form>
    );
  }
}

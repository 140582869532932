import React from 'react';

import FileUploader from "../fileUploader";
import Loading from "../loading";
import { strToJSONList, strToBool, cleanDropdown } from "../../utils";
import { downloadBulkEditSites, excelToJSON } from "./utils";
import { api } from '../../api';
import { hasXLSXExtension, fileSizeLessThanMB } from './utils';

const cleanTaxonomy = (taxonomy) => {
  let trimmedTaxonomy = taxonomy.map(t => t.trim());
  let deduppedTaxonomy = trimmedTaxonomy.filter((elem, pos) => {
      return trimmedTaxonomy.indexOf(elem) === pos;
  });
  return deduppedTaxonomy;
}

const fmtEditSiteBody = (site) => {
  return {
    site_id: site.site_id,
    suppress_address: strToBool(String(site.suppress_address)),
    active_flag: site.active_flag,
    site_name: site.site_name,
    site_budget: site.site_budget,
    notes: site.notes,
    accepts_dropins: strToBool(String(site.accepts_dropins)),
    language_taxonomy: cleanTaxonomy(strToJSONList(site.language_taxonomy)),
    service_taxonomy: cleanTaxonomy(strToJSONList(site.service_taxonomy)),
    population_taxonomy: cleanTaxonomy(strToJSONList(site.population_taxonomy)),
    service_setting_taxonomy: cleanTaxonomy(strToJSONList(site.service_setting_taxonomy)),
    bp_category: cleanTaxonomy(strToJSONList(site.bp_category)),
    bp_population: cleanTaxonomy(strToJSONList(site.bp_population)),
    capacity: site.capacity,
    capacity_units: cleanDropdown(site.capacity_units),
    serves_food: strToBool(String(site.serves_food)),
    fixed_address: strToBool(String(site.fixed_address)),
    address_1: site.address_1,
    address_2: site.address_2,
    borough: cleanDropdown(site.borough),
    city: cleanDropdown(site.city),
    state: cleanDropdown(site.state),
    zip: site.zip,
    catchment_type: cleanDropdown(site.catchment_type),
    catchment_zone: cleanTaxonomy(strToJSONList(site.catchment_zone)),
    catchment_intersection_1: site.catchment_intersection_1,
    catchment_intersection_2: site.catchment_intersection_2,
    catchment_description: site.catchment_description
  }
}

class BulkEditSite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
    }
  }

  error() {
    if (this.state.error) {
      return (
        <div className="mb-1">
          <div className="p-1 bg-primary-red-30t border border-primary-red animated fadeInUp">
            {this.state.error}
          </div>
        </div>
      );
    }
  }

   success() {
    if (this.state.success) {
      return <div className="p-1 mb-1 border animated fadeInUp" style={{backgroundColor: "#F0FFF4", borderColor: "#2F855A"}}>
        Successfully updated sites
      </div>
    }
  }

	bulkEditUpload() {
    const fileInput = document.getElementById('bulk-edit-file');
    const file = fileInput.files &&
      fileInput.files.length > 0 &&
      fileInput.files[0]

    if (!file) {
      return this.setState({
        error: "No file selected",
        loading: false,
        success: false
      })
    }

    if (!hasXLSXExtension(file.name)) {
      return this.setState({
        error: "File must be in XLSX format",
        loading: false,
        success: false
      })
    }

    if (!fileSizeLessThanMB(file.size, 1)) {
      return this.setState({
        error: "File must be smaller than 1MB",
        loading: false,
        success: false
      })
    }

    this.setState({
      loading: true
    });

    const reader = new FileReader();
    reader.onload = async (event) => {
      const [_, data] = excelToJSON(event.target.result);
      const sitesToEdit = data.map(fmtEditSiteBody);
      const method = "POST"
      const body = JSON.stringify({sites: sitesToEdit})
      api(this.props.unauthorize, "bulkeditsite", method, body)
        .then( res => {
         if (!res.status) {
           return this.setState({
             error: res.errors.message || "Unable to edit sites" ,
             success: null,
             loading: false
           })
         } else {
           this.setState({
             error: null,
             loading: false,
             success: true
           }, () => {
             this.props.updateContractsAndSites();
           })
         }
        })
      };
      reader.readAsArrayBuffer(file);
  }

  render() {
    return (
      <div>
        {this.success()}
        {this.error()}
        <div className="color-mid-background border p-2 inline-flex">
          <div className="w-1/3 pr-3 border-r-2 border-t-0 border-b-0 border-l-0 border-grey-900 border">
            <FileUploader
              inputID="bulk-edit-file"
            />
            <div className="mt-2 inline-flex">
              <div>
                <button className="py-2 px-3 outline-none btn color-primary-button" onClick={() => {
                  this.setState({
                    error: null,
                  })
                  this.bulkEditUpload()
                }}>Submit</button>
              </div>
              {this.state.loading && <Loading/>}
            </div>
          </div>
          <div className="w-2/3 ml-3">
            <p>
            Download a {' '}
            <button
              className="outline-none buttonLink"
              onClick={() => {
                downloadBulkEditSites(this.props.sites);
              }}>
              spreadsheet
            </button>
            {' '} of all sites associated with your agency, update desired rows, and upload below.
            </p>
            Modifications to <strong>site_id</strong>, <strong>contract_number</strong>, <strong>program_name</strong> and <strong>provider_name</strong> will be ignored.
          </div>
        </div>        
      </div>
    );
  }
}

export default BulkEditSite;

const apiURL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export function api(unauthorizeUser, resource, method, body) {
  let ok;
  const options = body ? {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.access_token
    },
    body: body
  } : {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.access_token
    }
  }
  return fetch(`${apiURL}/${resource}`, options)
  .then(res => {
    ok = res.ok;
    if (res.status === 401) {
      unauthorizeUser()
    }
    return res.json()
  })
  .then(
    (result) => {
      if (result.errors) {
        return {status: false, errors: result.errors};
      }
      return {status: ok, response: result};
    },
    (error) => {
      console.log(resource)
      console.log(error)
      return {status: false, errors: error};
    }
  )
}

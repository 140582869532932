import React from 'react';

import Tooltip from './tooltip';
import Multiselect from './multiselect';
import Loading from "./loading";
import { api } from '../api'
import { titleCase } from './../utils';

const collaboratingAgencies = require("./../../constants/collaborating_agencies.json");

class Program extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      program: {
        program_name: '',
        program_id: '',
        program_aka: '',
        umbrella: '',
        collaborating_agencies: [],
        program_description: '',
        program_website: '',
        plain_language_eligibility: '',
        mandated_program: '',
        how_to_apply: ''
      }
    };
  }

  componentDidMount(){
    if (this.props.location.state.program) {
      const program = this.props.location.state.program;
      if (program.collaborating_agencies === null) program.collaborating_agencies = []
      else if (!program.collaborating_agencies[0] || !program.collaborating_agencies[0].label) {
        program.collaborating_agencies = program.collaborating_agencies.map(m => ({label: titleCase(m), value: titleCase(m)})) || []
      }
      Object.keys(program).forEach( key => { if (!program[key]) program[key] = "" })
      program.mandated_program = !!program.mandated_program;
      this.setState({ program: program })
    }
  }

  handleChange = name => event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({program: Object.assign({}, this.state.program, {[name]: value})})
  };

  handleChangeMulti = (name, event) => {
    this.setState({program: Object.assign({}, this.state.program, {[name]: event})});
  }

  updateProgram() {
    const method = "POST"
    var {program_name, collaborating_agencies,  ...program} = this.state.program;
    const body = JSON.stringify({program: {
      ...program, 
      collaborating_agencies: collaborating_agencies.map(m => m.value)
    }});
    this.setState({
      loading: true
    })
    api(this.props.unauthorize, "program", method, body)
    .then( res => {
      if (!res.status) {
        return this.setState({
          error: "Unable to update program" ,
          loading: false
        })
      } else {
        this.setState({
          error: null,
          loading: false
        }, () => {
          this.props.updateContractsAndSites();
          this.props.history.replace({state: Object.assign(this.props.location.state, {program: this.state.program})})
        })
      }
    })
  }

  errorMessage() {
    if (this.state.error) {
      return (
        <div style={{"minWidth": "700px"}} className="w-1/2 text-center p-2 my-2 bg-primary-red-30t border border-primary-red animated fadeInUp">
          {this.state.error}
        </div>
      );
    }
  }

  render() {
    const program = this.state.program;
    return(
      <div>
        <h3 className="font-bold">
          {program.program_name}
        </h3>
        {this.errorMessage()}
        <div className="mt-3 w-1/2 text-sm leading-tight color-mid-background border border-primary-blue-30t p-3" style={{"minWidth": "700px"}}>

          <div className="w-full" >
            <fieldset>
              <label className="checkbox font-normal">
                <input id="mandated_program" type="checkbox" checked={program.mandated_program} onChange={this.handleChange('mandated_program')}/>
                <span className="checkbox__label">
                  <Tooltip
                    text="Are participants required to attend this program, for example as a condition of eligibility for benefits or a court mandate?"
                    child="Mandated for Program?"
                    inline={true}
                  />
                </span>
              </label>
            </fieldset>
          </div>

          <div className="mt-1">
            <Tooltip
              text="Alternative program name or acronym."
              child={"Program AKA"}
            />
            <div>
              <input id="program_aka" className="w-full" placeholder="program nickname..." value={program.program_aka} onChange={this.handleChange('program_aka')}/>
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text="This program does [this thing] for [this population] for [this reason]."
              child={"Program Description"}
            />
            <div>
              <textarea rows="3" id="program_description" className="w-full border pb-1" style={{borderColor: "#ACAEB9"}} placeholder="program description..." value={program.program_description} onChange={this.handleChange('program_description')}/>
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text="One or two sentences about how to apply, e.g. apply in person at a benefit center, apply online at apply.gov."
              child="How to Apply"
            />
            <div>
              <textarea rows="3" id="how_to_apply" className="w-full border pb-1" style={{borderColor: "#ACAEB9"}} placeholder="how to apply..." value={program.how_to_apply} onChange={this.handleChange('how_to_apply')}/>
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text={"Please describe eligibility requirements in plain language, for example \"A person is eligible if they are a NYCHA resident\" or \"A person is eligible if their income is below a certain level.\""}
              child={"Eligibility in Plain Language"}
            />
            <div>
              <textarea rows="3" id="plain_language_eligibility" className="w-full" style={{borderColor: "#ACAEB9"}} placeholder="plain language eligibility..." value={program.plain_language_eligibility} onChange={this.handleChange('plain_language_eligibility')}/>
            </div>
          </div>

          <div className="mt-2">
            <div>
              Citywide Initiative
            </div>
            <div className="input">
              <select id="umbrella" className="w-full full-select select border" style={{borderColor: "#ACAEB9", backgroundColor: "white"}} value={program.umbrella} onChange={this.handleChange('umbrella')}>
                <option value="">  -- select umbrella --  </option>
                <option key="Thrive" value="Thrive">Thrive</option>
                <option key="Children's Cabinet" value="Children's Cabinet">Children's Cabinet</option>
                <option key="YMI" value="YMI">YMI</option>
                <option key="" value="">None</option>
              </select>
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text="Select all that apply"
              child={"Collaborating Agencies"}
            />
            <div className="input">
              <Multiselect
                options={collaboratingAgencies}
                id={"collaborating_agencies"}
                placeholder={"select collaborating agencies..."}
                multi={program.collaborating_agencies}
                handleChangeMulti={this.handleChangeMulti}
              />
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text="Link to the program's website."
              child={"Program Website"}
            />
            <div>
              <input id="program_website" className="w-full" placeholder="program website url..." value={program.program_website} onChange={this.handleChange('program_website')}/>
            </div>
          </div>

        </div>

        <div className="mt-3 inline-flex">
          <button className="py-2 px-3 outline-none btn color-primary-button" onClick={() => {
            this.updateProgram();
          }}>Update</button>
          {this.state.loading && <Loading/>}
        </div>

      </div>
    );
  }
}

export default Program;

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const DOWNLOAD_SITES_HEADERS = require("../constants/download_sites_headers");
const DOWNLOAD_TABLE_HEADERS = require("../constants/download_contracts_headers");

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
const customCaseWords = {
  nyc: "NYC",
  "nyc:": "NYC:",
  nys: "NYS",
  "(nys)": "(NYC)",
  sco: "SCO",
  "(pc)": "(PC)",
  ymca: "YMCA",
  ny: "NY"
}

const hiddenExcelColumns = ["site_id"];

export const cleanDropdown = (x) => x && x.toLowerCase();

export const cleanTaxonomy = (taxonomy) => {
  let trimmedTaxonomy = taxonomy.map(t => t.trim()).map(t => t.toLowerCase());
  let deduppedTaxonomy = trimmedTaxonomy.filter((elem, pos) => {
      return trimmedTaxonomy.indexOf(elem) === pos;
  });
  return deduppedTaxonomy;
}

export const titleCase = (str) => {
  if (!str || typeof(str) !== "string") {
    return str
  }
  const allTitleCase = str.toLowerCase().split(' ').map(function(word) {
  	if (word && word.length > 1) {
  		return word.replace(word[0], word[0].toUpperCase());
  	}
    return word;
  })
  return allTitleCase.map(word => {
    const lowerCaseWord = word.toLowerCase();
    if (Object.keys(customCaseWords).includes(lowerCaseWord)) {
      return customCaseWords[lowerCaseWord];
    }
    return word;
  }).join(' ')
}

export const sentenceCase = (str) => {
  if (!str) {
    return str
  }
  const sentenceCased = str[0].toUpperCase() + str.substring(1).toLowerCase();
  return sentenceCased.split(' ').map(w => customCaseWords[w] ? customCaseWords[w] : w).join(' ');
}

export const getSiteXLSXCellValue = (header, site) => {
  switch (header.type) {
    case "json":
      return JSON.stringify(site[header.name]).replace(/\[|\]|"/g, '')
    case "boolean":
      return !!site[header.name]
    default:
      return site[header.name]
  }
}

export const downloadSites = (sites) => {
  const rows = sites
    .filter(site => site.site_id)
    .map(site => {
      let downloadSite = {};
      DOWNLOAD_SITES_HEADERS.forEach(h => {
        downloadSite[h.name] = getSiteXLSXCellValue(h, site)
      })
      return downloadSite;
    });
  downloadXLSX(rows, `sssld_sites_${Date.now()}`);
}

export const downloadContracts = (contracts, filename) => {
  const rows = contracts.map(contract => {
    let downloadContract = {};
    DOWNLOAD_TABLE_HEADERS.forEach(h => {
      downloadContract[h] = contract[h];
    })
    return downloadContract;
  });
  downloadXLSX(rows, filename)
}

// csvData is a list of json objects whose keys will become sheet header values and whose values will become sheet row values
export const downloadXLSX = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);

    // hide ID column so users don't edit
    if (csvData.length > 0 && Object.keys(csvData[0]).indexOf("site_id") > -1) {
      const wscols = Object.keys(csvData[0]).map(c => hiddenExcelColumns.includes(c) ? {hidden: true} : undefined);
      ws['!cols'] = wscols;
    }

    const wb = { Sheets: { 'sites': ws }, SheetNames: ['sites'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

export const strToJSONList = (x) => x && x.length > 0 ? x.split(',') : [];
export const strToBool = (x) => x && ["true", "t", "1"].includes(x.toLowerCase().trim()) ? true : false

import React from 'react'

import { siteInputClasses } from "./utils";
import Multiselect from '../multiselect';
import Tooltip from '../tooltip';

const catchmentTypes = require("../../../constants/catchment_type.json");
const catchmentZoneBorough = require("../../../constants/catchment_zone_borough.json");
const catchmentZoneCommunitDistrict = require("../../../constants/catchment_zone_community_district.json");
const catchmentZoneZipCode = require("../../../constants/catchment_zone_zip_code.json");

export default(props) => {

  const getCatchmentZones = () => {
    switch (props.catchment_type.toLowerCase()) {
      case "borough":
        return catchmentZoneBorough;
      case "community district":
        return catchmentZoneCommunitDistrict;
      case "zip code":
        return catchmentZoneZipCode;
      case "entire city":
        return ["All Boroughs"];
      default:
        return [];
    }
  }

  const getCatchmentZoneSelect = () => {
    const catchmentIsOther = props.catchment_type && props.catchment_type.toLowerCase() === "other"
    if (props.catchment_type && !catchmentIsOther) {
      return <Multiselect
        disabled={!props.active_flag}
        options={getCatchmentZones()}
        id={"catchment_zone"}
        placeholder={"select catchment zones..."}
        multi={props.catchment_zone}
        handleChangeMulti={props.handleChangeMulti}
        error={props.formErrors.includes("catchment_zone")}
      />
    }
    return <select id="catchment_zone" disabled className="full-select select border disabled-grey" value="">
      <option disabled value="">{catchmentIsOther ? "Catchment Type \"Other\" has no zones." : "please select catchment type first"}</option>
    </select>
  }
  return (
    <div>
      <div>

        <div className={props.formErrors.includes("catchment_type") ? 'error mt-1 w-full' : 'mt-1 w-full'}>
          <Tooltip
            text="Select the catchment type and zone(s) that best describe(s) the area covered by the program"
            child="Catchment Type "
            required={true}
          />
          <select disabled={!props.active_flag} id="catchment_type" value={props.catchment_type || ""} className={`full-select select border ${siteInputClasses(props)}`} onChange={props.handleCatchmentTypeChange}>
            <option disabled value="">choose type...</option>
            {catchmentTypes.map(ctype => {
              return <option key={ctype} value={ctype.toLowerCase()}>{ctype}</option>
            })}
          </select>
        </div>

        <div className={props.formErrors.includes("catchment_zone") ? 'error w-full mt-2' : "w-full mt-2"}>
          <Tooltip
            text="If applicable, select all that apply."
            child="Catchment Zones "
            required={props.catchment_type && props.catchment_type.toLowerCase() !== "other"}
          />
          {getCatchmentZoneSelect()}
        </div>
      </div>


      <div className="mt-2 w-full">
        <Tooltip
          text="The streets of an intersection, if services are provided at an intersection."
          child="Catchment Intersection"
        />
        <div className="flex">
          <div className="w-1/2">

            <div>
              <input id="catchment_intersection_1" className={siteInputClasses(props)} placeholder="street 1..." value={props.catchment_intersection_1} onChange={props.handleChange('catchment_intersection_1')}/>
            </div>
          </div>

          <div className="ml-1 w-1/2">
            <div>
              <input id="catchment_intersection_2" className={siteInputClasses(props)} placeholder="street 2..." value={props.catchment_intersection_2} onChange={props.handleChange('catchment_intersection_2')}/>
            </div>
          </div>
        </div>
      </div>
        <div className="mt-2 w-full">
          <Tooltip
            text="Description of where this service is delivered"
            child="Catchment Description "
            required={props.catchment_type && props.catchment_type.toLowerCase() === "other"}
          />
          <textarea rows="3" id="catchment_description" className={`border pb-1 ${siteInputClasses(props)}`} placeholder="catchment description..." style={props.formErrors.includes('catchment_description') ? {borderColor: 'red', color: 'red'} : {}} value={props.catchment_description} onChange={props.handleChange('catchment_description')}/>
        </div>
    </div>
  )
}

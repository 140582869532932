import React from "react";

export default(props) => {

  return (
    <div className="bg-base-white border-r-2">
      <div className="sticky top-0">
        <nav>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.top)}>Top</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.login)}>Login</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.app)}>Using the App</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.contracts)}>Manage Contracts</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.sites)}>Manage Sites</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.program)}>Enter Program Info</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.provider)}>Enter Provider Info</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.bulk)}>Using the Bulk Uploader</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.faq)}>FAQ</button>
          <button className="text-lg o-navigation__link" onClick={() => props.onButtonClick(props.refs.glossary)}>Glossary</button>
        </nav>
      </div>
    </div>
  )
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function FloatingActionButtons(args) {
  const classes = useStyles();

  return (
      <Fab 
        aria-label="delete" 
        className={classes.margin}
        disableRipple={true}
        size={args.size || 'small'}
        onClick={args.onClick}
      >
          {args.icon}
      </Fab>
  );
}
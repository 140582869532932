import React from 'react';

export default(props) => {
  var style = `color-dark-background text-white p-2 m-0 align-middle fixed bottom-0 animated right-0 ${props.showRecentlyCompleted ? "fadeInUp" : props.recentlyCompleted ? 'fadeOutDown' : 'invisible'} z-40 m-4 shadow flex`

  return(
    <div className={style} role="alert" style={{width: '500px'}}>
      <p className="font-bold p-1 mb-0 w-5/6">{props.recentlyCompleted}</p>
      <button className="btn color-secondary-button self-center w-1/6" onClick={props.handleUndo}>Undo</button>
    </div>
  )
}

import React from 'react';
import ReactDOM from 'react-dom';

const LogoutModal = (props) => props.logoutModal ? ReactDOM.createPortal(
  <React.Fragment>
  <div className="modal opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
      <div className="modal-content py-4 text-left px-6">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">Session Timeout</p>
        </div>
          <p>Your session has been inactive, would you like to stay logged in?</p>
        <div className="flex justify-end pt-2">
          <button className="px-4 p-3 btn color-primary-button btn-large flex" onClick={props.stayLoggedIn}>Yes</button>
          <button className="px-4 p-3 btn color-secondary-button btn-large" onClick={props.logout}>Logout</button>
        </div>
      </div>
    </div>
  </div>
  </React.Fragment>, document.body
) : null;

export default LogoutModal;

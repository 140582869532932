import { titleCase } from "./utils";

export const rowToSite = (row) => {
  return {
    site_id: row.data.site_id,
    active_flag: row.data.active_flag,
    bbl: row.data.bbl,
    bin: row.data.bin,
    community_district: row.data.community_district,
    council_district: row.data.council_district,
    latitude: row.data.latitude,
    longitude: row.data.longitude,
    state_senate_district: row.data.state_senate_district,
    state_assembly_district: row.data.state_assembly_district,
    nta: row.data.nta,
    census_tract: row.data.census_tract,
    catchment_type: row.data.catchment_type,
    catchment_zone: row.data.catchment_zone,
    catchment_intersection_1: row.data.catchment_intersection_1,
    catchment_intersection_2: row.data.catchment_intersection_2,
    catchment_description: row.data.catchment_description,
    address_1: row.data.address_1,
    address_2: row.data.address_2,
    borough: row.data.borough,
    city: row.data.city,
    state: row.data.state,
    zip: row.data.zip,
    site_name: row.data.site_name,
    site_budget: row.data.site_budget,
    notes: row.data.notes,
    accepts_dropins: row.data.accepts_dropins,
    suppress_address: row.data.suppress_address,
    language_taxonomy: row.data.language_taxonomy,
    service_taxonomy: row.data.service_taxonomy,
    population_taxonomy: row.data.population_taxonomy,
    service_setting_taxonomy: row.data.service_setting_taxonomy,
    bp_category: row.data.bp_category,
    bp_population: row.data.bp_population,
    capacity: row.data.capacity,
    capacity_units: row.data.capacity_units,
    fixed_address: row.data.fixed_address,
    serves_food: row.data.serves_food
  }
}

export const rowToProvider = (row) => {
  return {
    provider_name: titleCase(row.data.provider_name),
    provider_id: row.data.provider_id,
    hq_address_1: row.data.hq_address_1,
    hq_address_2: row.data.hq_address_2,
    hq_borough: row.data.hq_borough,
    hq_city: row.data.hq_city,
    hq_state: row.data.hq_state,
    hq_zip: row.data.hq_zip,
    provider_ein: row.data.provider_ein,
    provider_website: row.data.provider_website,
    contract_id: row.data.contract_id,
    contract_number: row.data.contract_number
  }
}

export const rowToProgram = (row) => {
  return {
    program_name: row.data.program_name,
    program_id: row.data.program_id,
    program_aka: row.data.program_aka,
    umbrella: row.data.umbrella,
    collaborating_agencies: row.data.collaborating_agencies,
    program_description: row.data.program_description,
    program_website: row.data.program_website,
    plain_language_eligibility: row.data.plain_language_eligibility,
    mandated_program: row.data.mandated_program,
    how_to_apply: row.data.how_to_apply
  }
}

export const rowToContract = (row) => {
  return {
    contract_id: row.contract_id,
    contract_number: row.contract_number,
    start_date: row.start_date,
    end_date: row.end_date,
    amount: row.amount,
    fiscal_year: row.fiscal_year,
    expense_category: row.expense_category,
    purpose: row.purpose,
    status: row.status,
    latest: row.latest,
    created_at: row.created_at,
    program_id: row.program_id,
    provider_id: row.provider_id,
    agency_id: row.agency_id
  }
}

import React from 'react';

import AsyncSelect from 'react-select/async';

const customStyles = {
  control: (styles, state) => {
    return !state.selectProps.formError ? {...styles} : {...styles, borderColor: 'red'}
  },
  input: (styles, state) => {
    return {
      height: 40,
      fontSize: 14,
      paddingTop: 8,
    }
  },
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? "#e6eeff" : "white",
      color: "black"
    }
  }
}

const loadOptions = (inputValue, callback) => {
  setTimeout(async () => {
    let ok;
    await fetch(`https://geosearch.planninglabs.nyc/v1/autocomplete?text=${inputValue}`)
      .then(res => {
        ok = res.ok;
        return res.json()
      })
      .then(
        (result) => {
          if (!ok) {
            console.log('geosearch error: ', result);
            return
          }
          let labels = result.features.map(f => f.properties)
          callback(labels);
        },
        (error) => {
          console.log('geosearch error: ', error);
        }
      )
  }, 100);
};



export default(props) => {
  const defaultOptions = props.geocodeInput ? [{
    label: props.geocodeInput,
    value: props.geocodeInput,
    name: props.address_1,
    borough: props.borough,
    postalcode: props.zip
  }] : []
  return (
      <div className="mb-2">
        <AsyncSelect
          isDisabled={!props.active_flag}
          styles={customStyles}
          formError={props.formErrors.includes('address_1')}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          onInputChange={event => props.handleChangeMulti('geocodeInput', event)}
          onChange={event => props.handleChangeMulti('geocodeAutocomplete', event)}
          placeholder={props.geocodeInput || "Search for address..."}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: "#3155A6",
              background: "white",
            },
          })}
        />
      </div>
    )
}

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { getSiteXLSXCellValue, downloadXLSX } from "../../utils";

const BULK_EDIT_SITES_HEADERS = require("../../../constants/bulk_edit_sites_headers");
const DROPDOWN_COLUMNS = require("./../../../constants/dropdowns.json");
const FULL_COLUMN_LIST = DROPDOWN_COLUMNS.map(d => ({name: d, data: require(`./../../../constants/${d}.json`)}));
const BULK_DATA_DICTIONARY = require("./../../../constants/bulk_data_dictionary.json");
const BULK_ADD_EXAMPLE = require("./../../../constants/bulk_add_example.json");

// csvObj is a list of [{sheetName: , data: }] pairs
const downloadXLSXWithSheets = (csvObj, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  let wb = { Sheets: {}, SheetNames: []};

  csvObj.forEach(obj => {
    const ws = XLSX.utils.json_to_sheet(obj.data);
    wb["Sheets"][obj.sheetName] = ws;
    wb["SheetNames"].push(obj.sheetName);
  });

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

const getDropdownOptionJSON = () => {
  let maxColLength = 0;
  FULL_COLUMN_LIST.forEach(c => {
    if (c.data.length > maxColLength) {
      maxColLength = c.data.length;
    }
  });
  let dropdownOptionsJSON = [];
  for (var i = 0; i < maxColLength; i++) {
    let thisRow = {}
    for (var j = 0; j < FULL_COLUMN_LIST.length; j++) {
      let fullCol = FULL_COLUMN_LIST[j];
      const thisValue = fullCol.data.length > i ? fullCol.data[i] : undefined;
      thisRow[fullCol.name] = thisValue
    }
    dropdownOptionsJSON.push(thisRow);  
  }
  return dropdownOptionsJSON
}

const getDataDictionaryJSON = () => {
  return BULK_DATA_DICTIONARY
}

const getBulkAddExample = () => {
  return BULK_ADD_EXAMPLE
}

export const downloadExampleSchemaXLSX = () => {
  downloadXLSXWithSheets([
    {
      sheetName: "Bulk Add Schema",
      data: getBulkAddExample()
    },
    {
      sheetName: "Data Dictionary",
      data: getDataDictionaryJSON()
    },
    {
      sheetName: "Dropdown Options",
      data: getDropdownOptionJSON()
    }
  ], "example_schema.xlsx")
}

export const findMissingXLSXHeader = (requiredHeaders, inputHeaders) => {
  inputHeaders = inputHeaders.map(h => h.toLowerCase())
  for (var i = 0; i < requiredHeaders.length; i++) {
    const thisRequiredHeader = requiredHeaders[i];
    if (!inputHeaders.includes(thisRequiredHeader.toLowerCase())) {
      return thisRequiredHeader
    }
  }
}

export const downloadBulkEditSites = (sites) => {
  const rows = sites
    .filter(site => site.site_id)
    .map(site => {
      let downloadSite = {};
      BULK_EDIT_SITES_HEADERS.forEach(h => {
        downloadSite[h.name] = getSiteXLSXCellValue(h, site)
      })
      return downloadSite;
    });
  downloadXLSX(rows, `sssld_bulk_edit_sites_${Date.now()}`);
}

const getXLSXHeaders = (ws) => {
  // filter logic assumes that headers take place in cells of format [A-Z]1.
  return Object.keys(ws).filter(k => k && k.length === 2 && k[1] === "1").map(k => ws[k].v) 
}

export const excelToJSON = (file) => {
  const wb = XLSX.read(file, {type:'array'});
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  let jsonRows = XLSX.utils.sheet_to_json(ws);
  let cleanRows = jsonRows.map(row => Object.assign({}, ...Object.keys(row).map(k => ({[k.toLowerCase()]: typeof(row[k]) === "string" ? row[k].trim() : row[k]}))));

  const headers = getXLSXHeaders(ws);
  return [headers, cleanRows];
}

export const hasXLSXExtension = (fileName) => {
	return fileName.match(/.*\.xlsx/);
}

export const fileSizeLessThanMB = (fileSize, maxSizeMB) => {
	const filesize = ((fileSize/1024)/1024).toFixed(4);  // in MB
	return filesize < maxSizeMB;
}

import React from 'react';

import Tooltip from './tooltip';
import Loading from "./loading";
import { api } from '../api'

const boroughs = require("./../../constants/borough.json");
const states = require("./../../constants/states.json");

class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      provider: {
        provider_id: "",
        hq_address_1: "",
        hq_address_2: "",
        hq_borough: "",
        hq_city: "",
        hq_state: "",
        hq_zip: "",
        provider_ein: "",
        provider_website: "",
      }
    };
  }

  componentDidMount(){
    if (this.props.location.state) {
      var obj = this.props.location.state.provider
      Object.keys(obj).forEach( key => { if (!obj[key]) obj[key] = "" })
      if (!obj.hq_state) obj.hq_state = "NY"
      this.setState({ provider: obj })
    }
  }

  handleChange = name => event => {
    this.setState({provider: Object.assign({}, this.state.provider, {[name]: event.target.value})})
  }

  updateProvider() {
    const method = "POST"
    var {provider_name, contract_id, contract_number, ...provider} = this.state.provider
    const body = JSON.stringify({provider: provider})

    this.setState({
      loading: true
    })
    api(this.props.unauthorize, "provider", method, body)
    .then( res => {
      if (!res.status) {
        return this.setState({
          error: "Unable to update provider" ,
          loading: false
        })
      } else {
        this.setState({
          error: null,
          loading: false
        }, () => {
          this.props.updateContractsAndSites();
          this.props.history.replace({state: Object.assign(this.props.location.state, {provider: this.state.provider})})
        })
      }
    })
  }


  errorMessage() {
    if (this.state.error) {
      return (
        <div style={{minWidth: "700px"}} className="w-1/2 text-center p-2 my-2 bg-primary-red-30t border border-primary-red animated fadeInUp">
          {this.state.error}
        </div>
      );
    }
  }

  render() {
    return(
      <div>
        <h3 className="font-bold">
          {this.state.provider.provider_name}
        </h3>
        {this.errorMessage()}
        <div className="mt-4 w-1/2 text-sm leading-tight color-mid-background border border-primary-blue-30t p-3" style={{minWidth: "700px"}}>

          <div>
            <div>
              HQ Address 1
            </div>
            <div className="input">
              <input id="hq_address_1" className="w-full" placeholder="HQ address 1..." value={this.state.provider.hq_address_1} onChange={this.handleChange('hq_address_1')}/>
            </div>
          </div>

          <div className="mt-2">
            <div>
              HQ Address 2
            </div>
            <div>
              <input id="hq_address_2" className="w-full" placeholder="HQ address 2..." value={this.state.provider.hq_address_2} onChange={this.handleChange('hq_address_2')}/>
            </div>
          </div>

          <div className="mt-2 w-full">
            <div className="flex">
              <div className="w-1/3">
                <div>
                  City
                </div>
                <div>
                  <input id="hq_city" className="w-full" placeholder="city..." value={this.state.provider.hq_city} onChange={this.handleChange('hq_city')}/>
                </div>
              </div>

              <div className="w-1/3 ml-1">
                <div>
                  State
                </div>
                <div>
                  <select id="hq_state" className="w-full select border" style={{borderColor: "#ACAEB9", backgroundColor: "white"}} value={this.state.provider.hq_state} onChange={this.handleChange('hq_state')}>
                    <option key={"NY"} default value="NY">NY</option>
                    {states.filter(s => s !== "NY").map(s => <option key={s} value={s}>{s}</option>)}
                  </select>
                </div>
              </div>

              <div className="w-1/3 ml-1">
                <div>
                  Zipcode
                </div>
                <div>
                  <input id="hq_zip" type="number" className="w-full" placeholder="zipcode..." value={this.state.provider.hq_zip} onChange={this.handleChange('hq_zip')}/>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div>
              Borough
            </div>
            <div>
              <select id="hq_borough" className="w-full select border" style={{borderColor: "#ACAEB9", backgroundColor: "white"}} value={this.state.provider.hq_borough} onChange={this.handleChange('hq_borough')}>
                <option disabled value="">select borough...</option>
                {boroughs.map(b => {
                  return <option key={b} value={b}>{b}</option>
                })}
              </select>
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text="IRS Employer Identification Number"
              child={"EIN"}
            />
            <div>
              <input id="provider_ein" className="w-full" placeholder="ein number..." value={this.state.provider.provider_ein} onChange={this.handleChange('provider_ein')}/>
            </div>
          </div>

          <div className="mt-2">
            <Tooltip
              text="Link to the provider's website"
              child={"Website"}
            />
            <div>
              <input id="provider_website" className="w-full" placeholder="website url..." value={this.state.provider.provider_website} onChange={this.handleChange('provider_website')}/>
            </div>
          </div>
        </div>

        <div className="mt-3 inline-flex">
              <button className="py-2 px-3 outline-none btn color-primary-button" onClick={() => {
                this.updateProvider()
              }}>Submit</button>
              {this.state.loading && <Loading/>}
          </div>
      </div>
    );
  }
}

export default Provider;

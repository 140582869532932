import React from 'react';
import { Link } from 'react-router-dom';

import FileUploader from "../fileUploader";
import Loading from "../loading";
import { strToJSONList, strToBool, downloadXLSX, downloadContracts, cleanTaxonomy, cleanDropdown } from "../../utils";
import { downloadExampleSchemaXLSX, findMissingXLSXHeader, excelToJSON } from "./utils";
import { api } from '../../api';
import { hasXLSXExtension, fileSizeLessThanMB } from './utils';

const BULK_ADD_SCHEMA_EXAMPLE = require("./../../../constants/bulk_add_example.json");

const fmtAddSiteBody = (site) => {
  Object.keys(site).forEach(k => {
    if (site[k] === "") {  // site was uploaded from spreadsheet, so "" should be treated as not specified.
      delete site[k]
    }
  })
  return {
    contract_id: site.contract_id,
    provider_id: site.provider_id,
    program_id: site.program_id,
    active_flag: true,
    catchment_type: cleanDropdown(site.catchment_type),
    catchment_zone: cleanTaxonomy(strToJSONList(site.catchment_zone)),
    catchment_intersection_1: site.catchment_intersection_1,
    catchment_intersection_2: site.catchment_intersection_2,
    catchment_description: site.catchment_description,
    address_1: site.address_1,
    address_2: site.address_2,
    borough: cleanDropdown(site.borough),
    city: cleanDropdown(site.city),
    state: cleanDropdown(site.state),
    zip: site.zipcode,
    site_name: site.site_name,
    site_budget: site.site_budget,
    notes: site.notes,
    accepts_dropins: strToBool(String(site.accepts_dropins)),
    suppress_address: strToBool(String(site.suppress_address)),
    language_taxonomy: cleanTaxonomy(strToJSONList(site.language_taxonomy)),
    service_taxonomy: cleanTaxonomy(strToJSONList(site.service_taxonomy)),
    population_taxonomy: cleanTaxonomy(strToJSONList(site.population_taxonomy)),
    service_setting_taxonomy: cleanTaxonomy(strToJSONList(site.service_setting_taxonomy)),
    bp_category: cleanTaxonomy(strToJSONList(site.bp_category)),
    bp_population: cleanTaxonomy(strToJSONList(site.bp_population)),
    capacity: site.capacity,
    capacity_units: cleanDropdown(site.capacity_units),
    fixed_address: strToBool(String(site.fixed_address)),
    serves_food: strToBool(String(site.serves_food))
  }
}

class BulkAddSite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
    }
  }

  error() {
    if (this.state.error) {
      return (
        <div className="mb-1">
          <div className="p-1 bg-primary-red-30t border border-primary-red animated fadeInUp">
            {this.state.error}
          </div>
        </div>
      );
    }
  }

   success() {
    if (this.state.success) {
      return <div className="p-1 mb-1 border animated fadeInUp" style={{backgroundColor: "#F0FFF4", borderColor: "#2F855A"}}>
        Successfully added sites
      </div>
    }
  }

  verifyContractProgramAssociations(sites) {
  	for (let i=0; i < sites.length; i++) {
      let site = sites[i];
      // make sure that no two contracts have different program ids.
      let sameContractDifferentProgramSiteIdx = sites.findIndex(s => (s.contract_id === site.contract_id) && (s.program_id !== site.program_id));
      if (sameContractDifferentProgramSiteIdx > -1) {
      	return `Rows ${i} and ${sameContractDifferentProgramSiteIdx} have the same contract number, but different progam names. Contracts cannot be associated with more than one program.`;
      }
    }
  }

  setError(msg) {
  	return this.setState({
      error: msg,
      loading: false,
      success: false
    })
  }

  bulkAddUpload() {
    const fileInput = document.getElementById('bulk-add-file');
    const file = fileInput.files &&
      fileInput.files.length > 0 &&
      fileInput.files[0];

    if (!file) {
      return this.setError("No file selected");
    }

    if (!hasXLSXExtension(file.name)) {
      return this.setError("File must be in XLSX format");
    }

    if (!fileSizeLessThanMB(file.size, 1)) {
      return this.setError("File must be smaller than 1MB");
    }

    this.setState({
      loading: true,
      success: false,
      error: null
    });

    const reader = new FileReader();;
    reader.onload = async (event) => {
        // should add a level of verification to make sure all columns are provided

        let sitesToAdd = [];
        const [headers, data] = excelToJSON(event.target.result);
        const requiredHeaders = Object.keys(BULK_ADD_SCHEMA_EXAMPLE[0]);
        const misingHeader = findMissingXLSXHeader(requiredHeaders, headers);
        if (misingHeader) {
          return this.setError(`Uploaded file is missing "${misingHeader}" column`)
        }

        for (let i =0; i < data.length; i++) {
          let site = data[i];
          // ensure contract number belongs to a known contract
          let contract = this.props.contracts.find(c => c.contract_number.toLowerCase() === site.contract_number.toLowerCase());
          if (!contract) {
            return this.setError(`Unknown contract ${site.contract_number}`)
          }

          // if contract is being associated with a program, make sure it is a known program
          if (!contract.program_id) {

            const knownProgram = this.props.programs.find(p => site.program_name.toLowerCase() === p.program_name.toLowerCase());
            if (!knownProgram) {
              return this.setError(`Unknown program ${site.program_name}`);
            }
            site.program_id = knownProgram.program_id;
          }
          sitesToAdd.push(Object.assign({}, contract, site));
        };


        const contractProgramErr = this.verifyContractProgramAssociations(sitesToAdd);
        if (contractProgramErr) {
        	return this.setError(contractProgramErr);
        }

        sitesToAdd = sitesToAdd.map(fmtAddSiteBody)
        const method = "POST"
        const body = JSON.stringify({sites: sitesToAdd})

        api(this.props.unauthorize, "bulkaddsite", method, body)
        .then( res => {
          if (!res.status) {
            return this.setState({
              error: res.errors.message || "Unable to add sites" ,
              success: null,
              loading: false
            })
          } else {
            this.setState({
              error: null,
              loading: false,
              success: true
            }, () => {
              this.props.updateContractsAndSites();
            })
          }
        })
    };
    reader.readAsArrayBuffer(file);
  }

  render() {
    return (
      <div>
        {this.success()}
        {this.error()}
        <div className="color-mid-background border p-2 inline-flex">
          <div className="w-1/3 pr-3 border-r-2 border-t-0 border-b-0 border-l-0 border-grey-900 border">
            <FileUploader
              inputID="bulk-add-file"
            />
            <div className="mt-2 inline-flex">
              <div>
                <button className="py-2 px-3 outline-none btn color-primary-button" onClick={() => {
                  this.setState({
                    error: null,
                  })
                  this.bulkAddUpload()
                }}>Submit</button>
              </div>
              {this.state.loading && <Loading/>}
            </div>
          </div>
          <div className="w-2/3 ml-3">
            <p>
              Upload a spreadsheet that conforms to the {' '}
              <button
                className="outline-none buttonLink"
                onClick={() => {
                  downloadExampleSchemaXLSX();
                }}>
                bulk upload guide's
              </button> {' '} Bulk Add Schema. The required fields are <strong>site_name</strong>, <strong>contract_number</strong>, <strong>program_name</strong> and <strong>fixed_address</strong>. If fixed_address is true, then <strong>address_1</strong> and <strong>zipcode</strong> are required, otherwise <strong>catchment_type</strong> and <strong>catchment_zone</strong> are required. If <strong>catchment_type</strong> is <strong>"Other"</strong>, then <strong>catchment_description</strong> is required instead of <strong>catchment_zone</strong>.
            </p>
            <p>
              All contract numbers and program names in the uploaded file must match {' '}
              <button
                className="outline-none buttonLink"
                onClick={() => {
                  downloadContracts(this.props.contracts, `sssld_known_contracts_${Date.now()}`);
                }}>
                known contracts
              </button> and {' '}
              <button
                className="outline-none buttonLink"
                onClick={() => {
                  downloadXLSX(this.props.programs.map(p => ({program_name: p.program_name})), `sssld_programs_${Date.now()}`);
                }}>
                known programs
              </button>. If you would like to add a program to this list, please <a href={"mailto:" + process.env.REACT_APP_ADD_PROGRAM_EMAIL}>submit a request</a>.
            </p>
            If you add a site to a contract that has <Link to="/contracts">not yet been associated with a program</Link>, the association will be made automatically.
        </div>
      </div>
    </div>
    )
  }
}

export default BulkAddSite;

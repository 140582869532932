import ContractImg from "../../assets/SSSLD_contract_page.png";
import ContractSearchImg from "../../assets/SSSLD_contract_search.png";
import AddSitesImg from "../../assets/SSSLD_add_sites.png";
import RequestProgramImg from "../../assets/SSSLD_request_new_program.png";
import ProgramFormImg from "../../assets/SSSLD_program_form.png";
import BulkUploadImg from "../../assets/SSSLD_bulk_upload.png";
import TaxonomyXLSX from "../../assets/taxonomy_definitions.xlsx";
import React, { useRef } from "react";
import Nav from './nav'
import { downloadXLSX } from "../../utils";

const BULK_ADD_SCHEMA_EXAMPLE = require("./../../../constants/bulk_add_example.json");

export default() => {

  const sectionHeader = (text) => {
    return (
      <div className="my-2 mt-3">
        <h4 className="text-primary-blue">
          {text}
        </h4>
        <hr className="bg-primary-blue"/>
      </div>
    );
  }

  const refLink = (ref, text) => {
    return <button className="text-primary-blue outline-none font-bold" onClick={() => onButtonClick(ref)}>{text}</button>
  }


  const onButtonClick = ref => {
    if (ref === refs.top) return window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    return window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: 'smooth'})
  };

  const refs = {
    top: useRef(null),
    login: useRef(null),
    app: useRef(null),
    contracts: useRef(null),
    sites: useRef(null),
    program: useRef(null),
    provider: useRef(null),
    bulk: useRef(null),
    faq: useRef(null),
    glossary: useRef(null)
  }


    return (
      <div className="layout-sidebar w-full screen-tablet:flex h-full color-light-background py-6">
        <Nav refs={refs} onButtonClick={onButtonClick.bind(this)}/>
        <div className="px-6" ref={refs.top}>
          <h2>Contracted Social Service Site Locations</h2>
          <h3>Instructions for Using the Data Collection Application</h3>
          <div ref={refs.login}>
            {sectionHeader("Part 1: User Login")}
            <p>The data collection application integrates with NYC.ID so you can log into the app using your regular City email and password (the same you use to log in to your computer and email). Only users that have been specifically provisioned will have access to the app. Your agency liaison submitted a list of users during the set-up process—if you do not have access to the app and think you should, please have your agency liaison contact us at <a href={`mailto:${process.env.REACT_APP_ADD_PROGRAM_EMAIL}?Subject=Unauthorized%20User`} target="_top">ProgramSites@nycopportunity.nyc.gov</a>.</p>
            <p>To log in:</p>
            <ol className="list-decimal list-inside numbered-list ml-1">
              <li className="numbered-list-elem mt-1">Using a web browser (we recommend Chrome for the best experience), navigate to the application. The URL is <a href="/">https://programsites.cityofnewyork.us</a>.</li>
              <li className="numbered-list-elem mt-1">You will be directed to the NYC.ID log-in page. Select “Log in as a NYC Employee”.</li>
              <li className="numbered-list-elem mt-1">Enter your regular City email and password.</li>
            </ol>
            <p>There are two options for entering information in the application. The first is to update information using the <a href="/sites">application user interface</a>. The second is to use the <a href="/upload">bulk upload tool</a> to upload an XLSX file. With either method, we are asking for three kinds of information: information about {refLink(refs.glossary, "sites")}, {refLink(refs.glossary, "programs")}, and {refLink(refs.glossary, "providers")}.</p>
          </div>
          <div ref={refs.app}>
            {sectionHeader("Part 2: Entering Data using the App")}
            <div ref={refs.contracts}>
              <h5>Step 1: Manage Contracts: Associate Programs with Contracts</h5>
              <p>In this step, we are asking you to tell us which program goes with each contract. On this page, you will see the {refLink(refs.glossary, "Contract ID number")}, the {refLink(refs.glossary, "provider name")}, the {refLink(refs.glossary, "contract purpose")}, and a drop-down menu with your agency’s program names.
              For each contract, select the associated program from the dropdown menu.
              </p>
              <p><img className="border-2" src={ContractImg} alt="Program dropdown with sample program options."/></p>
              <p>
              If you need programs added or removed to the dropdown menu, click <a href={`mailto:${process.env.REACT_APP_ADD_PROGRAM_EMAIL}`}>“Request new program be added to dropdown”</a>
              </p>
              <p>
              <img className="border-2" src={RequestProgramImg} alt="Link to email application admin for adding missing programs is highlighted."/>
              </p>
              <p>
              We have filtered the contract list to contracts classified in FMS as “Human Services” contracts. Sometimes those contracts are for things like staff training or textbooks and should not be included. If you believe a contract is not for programming, please click the “Remove” button. If you remove a contract in error, click the “Undo” button. To learn more about what should and should not be included, read the following {refLink(refs.faq, "FAQ")}.
              </p>
              <h5>Tips and Tricks:</h5>
              <p>
              You can search for a specific provider, contract number, or contract purpose using the search bar in the top right.
              </p>
              <p>
              <img className="border-2" src={ContractSearchImg} alt="Search field for contracts is highlighted."/>
              </p>
              <p>
              You have a fifteen second window to hit undo after you remove a contract. After that, if you need a contract re-instated, please drop us a note at <a href={`mailto:${process.env.REACT_APP_ADD_PROGRAM_EMAIL}`}>ProgramSites@nycopportunity.nyc.gov</a>.
              To download all your data, use the Download link on the top right of the page. This download will also include geocoded districts for all your locations!
              </p>
            </div>
            <div ref={refs.sites}>
              <h5>Step 2: Manage Sites: Add Locations to Programs</h5>
              <p>In this step, we are asking you to tell us where each program is being delivered. On this page, you will see the list of program names with an arrow before each of them. Clicking the arrow next to the program name opens the program and shows you all the associated contracts and providers.
              To add a service delivery site or catchment area to a contract, click the blue “Add Sites” button to the right of the contract.
              </p>
              <p>
              <img className="border-2" src={AddSitesImg} alt="Add Sites button is highlighted on the Manage Sites page."/>
              </p>
              <p>
              Fill in the information about the site to the best of your ability. Required fields are the site name, and address or catchment zone.
              For more information about the taxonomy values and what they mean, read the following {refLink(refs.faq, "FAQ")}.
              Click “Create” to add your site.
              </p>
              <h5>Tips and Tricks:</h5>
              <p>If a contract active in FY18 is still active now AND you told us the locations associated with that contract in the first round of data collection, those locations are already included for you. Edit them as needed.
              To download all your data, use the Download link on the top right of the page. This download will also include geocoded districts for all your locations!
              </p>
            </div>
            <div ref={refs.program}>
              <h5>Step 3: Enter Program Information</h5>
              <p>In this step, we are asking you to tell us a little about the program and what it does.
              From the “Manage Sites” page, click on the program name. Fill in the information about the program and then click “Update”.
              </p>
              <p>
              <img className="border-2" src={ProgramFormImg} alt="Selecting a program in the Manage Sites page opens a detailed view of the program with the ability to add information."/>
              </p>
            </div>
            <div ref={refs.provider}>
              <h5>Step 4: Enter Provider Information</h5>
              <p>In this step, we are asking you tell us a little about the provider and where their central headquarters is. Please note you only need to do this once for a provider even if they have multiple contracts.
              From the “Manage Sites” or the “Manage Contracts” page, click on the name of a provider. Enter the provider’s headquarters address, EIN number, and link to their website.
              </p>
            </div>
          </div>
          <div ref={refs.bulk}>
            {sectionHeader("Part 3: Entering Data Using the Bulk Uploader")}
            <p>If you already have a database of service locations tied to their contracts or if you would prefer to compile the information in a spreadsheet all at once, there is a bulk upload function available. Detailed instructions are provided on the bulk upload page—follow them closely. There are two options for bulk uploading: bulk adding and bulk editing. For either option, your spreadsheet will need to perfectly match the
            {' '} <button
                className="outline-none buttonLink"
                onClick={() => {
                  downloadXLSX(BULK_ADD_SCHEMA_EXAMPLE, `sssld_bulk_add_example`);
                }}>
                template provided
            </button>. You can find the bulk uploader by clicking "Upload Sites" from the sidebar.</p>
            <p>
            <img className="border-2" src={BulkUploadImg} alt="The bulk upload page."/>
            </p>
            <h5>Option 1: Bulk Add Sites</h5>
            <p>Download the example schema provided. Required fields are detailed on the bulk upload page. Note that the contract number and program name must match a known contract number or program name to be successful.</p>
            <h5>Option 2: Bulk Edit Sites</h5>
            <p>Once your sites have been created, you can download the spreadsheet of all of the sites and make changes in the spreadsheet and re-upload to make the changes in bulk. Please do not modify the site ID, contract number, program name, or provider name. You can change any of the other fields.</p>
            <h5>Tips and Tricks:</h5>
            <p>Use a comma to delimit fields that can have multiple values. For example, if a location offers services in both Spanish and English, the cell in the spreadsheet would look like this: Spanish, English. The order is not important.</p>
          </div>
          <div ref={refs.faq}>
            {sectionHeader("FAQ")}
            <p><b>Where does the pre-populated contract data come from?</b>
            <br/>
            We pull active contract data from the <a href="https://www.checkbooknyc.com">Comptroller’s Checkbook API</a>. The data in the Checkbook API reflects data in FMS, which is the current universal listing of contracts for the City.
            </p>
            <p><b>What should I do if I can’t find a program name in the drop-down menu in the Manage Contracts section?</b>
            <br/>
            Please email us at <a href={`mailto:${process.env.REACT_APP_ADD_PROGRAM_EMAIL}`}>ProgramSites@nycopportunity.nyc.gov</a> with the program name you need added.
            </p>
            <p><b>What are all the taxonomy options?</b>
            <br/>
            Great question!  Here is a <a href={TaxonomyXLSX} download>guide</a>!
            </p>
          </div>
          <div ref={refs.glossary}>
            {sectionHeader("Glossary")}
            <ul>
              <li><b>Contract ID</b></li>
                <ul>
                  <li>The external contract ID assigned to a contract in FMS. It usually starts with "CT".</li>
                </ul>
              <li><b>FMS</b></li>
                <ul>
                  <li>The City's Financial Management System. Administered by FISA, this is the current system of record for all city contracts.</li>
                </ul>
              <li><b>Checkbook NYC</b></li>
                <ul>
                  <li>The Comptroller's <a href="https://www.checkbooknyc.com">public, online tool</a> that publishes contract, budget, revenue, spending, and payroll data. This tool also includes an API which we use to get the most up-to-date contract data.</li>
                </ul>
              <li><b>Program</b></li>
                <ul>
                  <li>Flexibly speaking, a service or collection of services offered to New Yorkers that is designed to address a particular long-term aim. A program can require a referral, be for people who meet a set of criteria, or be open to all.
                  For example, Jobs-Plus is a program for working-age New Yorkers who live in NYCHA housing and also receive benefits from HRA designed to increase their earnings and employment. However, programs like WorkWell would not fit this criteria
                  because they are only open to employees of the City. We recognize that defining a program is tricky and encourage you to use your best judgment and <a href={`mailto:${process.env.REACT_APP_ADD_PROGRAM_EMAIL}`}>talk it out</a> with us if there are questions.</li>
                </ul>
              <li><b>Human Services</b></li>
                <ul>
                  <li>In this context, human services means via services provided by humans via contract to New Yorkers. It does not include contracts for material goods, leases for physical property, or staff training.</li>
                </ul>
              <li><b>Site / Service Location</b></li>
                <ul>
                  <li>A site is a physical point where a program is provided. Also known as a service location.</li>
                </ul>
              <li><b>Headquarters Location</b></li>
                <ul>
                  <li>A headquarters location is an administrative address or back-office location for a provider. A headquarters location may also be a service location, but also may not be.</li>
                </ul>
              <li><b>Catchment Area</b></li>
                <ul>
                  <li>A catchment area is a geography in which a program is provided. An example of a catchment area would be a home visiting service that cover specific community districts. Catchments can also be intersections or roads
                  in the case of mobile vans, or entire boroughs.</li>
                </ul>
              <li><b>Provider</b></li>
                <ul>
                  <li>A provider is a vendor, usually a community based organization, that contracts with the city to deliver a program or programs. Provider names and information come from FMS and are attached to the contract.</li>
                </ul>
            </ul>
          </div>
        </div>
      </div>

    )
}

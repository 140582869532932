import React from 'react'

import Tooltip from '../tooltip';
import { siteInputClasses } from "./utils";

export default(props) => {
  return (
    <div className="text-sm leading-tight">
      {!props.newSite &&
        <div className="mb-1">
          <fieldset>
          <label className="checkbox font-normal">
            <input id="active_flag" type="checkbox" checked={props.active_flag} onChange={props.handleChange('active_flag')} />
            <span className="checkbox__label">
              <Tooltip
                text="Current status of the site. Please uncheck if the site is no longer active under this contract and provider."
                child="Is Active?"
                inline={true}
              />
            </span>
          </label>
        </fieldset>
      </div>
      }
      <div>
        <Tooltip
          text="The name of the site, for example PS9 or St. Mary's Senior Center"
          child="Site Name "
          required={true}
        />
        <div className={props.formErrors.includes("site_name") ? 'error' : ''}>
          <input disabled={!props.active_flag} id="site_name" className={siteInputClasses(props)} placeholder="site name..." value={props.site_name} onChange={props.handleChange("site_name")} required/>
        </div>
      </div>
      <div className="mt-2">
        <Tooltip
          text="The budget for the site (only including city funding) if known."
          child="Site Budget"
        />
        <div className="input-currency-usd">
          <input disabled={!props.active_flag} id="site_budget" type="number" className={siteInputClasses(props)} placeholder="10,000..." value={props.site_budget} onChange={props.handleChange("site_budget")}/>
        </div>
      </div>
      <div className="mt-2">
        <div>
          Notes
        </div>
        <div>
          <textarea disabled={!props.active_flag} id="notes" className={siteInputClasses(props)} placeholder="notes..." style={{"borderColor": "#ACAEB9"}} value={props.notes == null ? "" : props.notes} onChange={props.handleChange("notes")}/>
        </div>
      </div>
    </div>
  )
}

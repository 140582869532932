import React, { Component } from "react";
import { Route } from "react-router-dom";

import "./App.scss";
import Header from "./componentsV2/header";

import Routes from "./Routes";
import { isAuthenticated } from './hoc/isAuthenticated';
const AuthenticatedRoutes = isAuthenticated(Routes)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: true,
    };
  }

  componentDidMount(){
    document.title = "Program Sites"
  }

  toggleMenu = () => this.setState({ menu: !this.state.menu})

  render() {
    return (
      <React.Fragment>
        <Header
          toggleMenu={this.toggleMenu.bind(this)}
        />
        <Route
          path ='/'
          render={(props) => <AuthenticatedRoutes {...props} menu={this.state.menu} />}
        />
      </React.Fragment>
    );
  }
}

export default App;

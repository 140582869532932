import React from 'react'

import { siteInputClasses } from "./utils";
import Geosearch from "../geosearch";

export default(props) => {

  return (
    <div className="leading-tight">
      <div className="mt-1 w-full">
        <div>
          <div>
            Address <span className='text-primary-red'>*</span>
          </div>
          <Geosearch
            {...props}
          />
        </div>
      </div>

      <div className="mt-2">
        <div>
          Additional Address Info
        </div>
        <div>
          <input disabled={!props.active_flag} className={siteInputClasses(props)} id="address_2" placeholder="apt no., floor number, etc." value={props.address_2} onChange={props.handleChange('address_2')}/>
        </div>
      </div>

      {!props.newSite && <div>
        <div className="mt-3 w-full">
          <div className="flex">
            <div className="w-1/3">
              <div>
                Borough Block Lot
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="bbl..." value={props.bbl}/>
              </div>
            </div>

            <div className="w-1/3 ml-1">
              <div>
                Building Identification Number
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="bin..." value={props.bin}/>
              </div>
            </div>

            <div className="w-1/3 ml-1">
              <div>
                Community District
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="community district..." value={props.community_district}/>
              </div>
            </div>

          </div>
        </div>
        <div className="mt-2 w-full">
          <div className="flex">
            <div className="w-1/3">
              <div>
                Council
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="council..." value={props.council_district}/>
              </div>
            </div>

            <div className="w-1/3 ml-1">
              <div>
                NTA
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="nta..." value={props.nta}/>
              </div>
            </div>

            <div className="w-1/3 ml-1">
              <div>
                Census Tract
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="census tract..." value={props.census_tract}/>
              </div>
            </div>

          </div>
        </div>

        <div className="mt-2 w-full">
          <div className="flex">
            <div className="w-1/2">
              <div>
                State Senate District
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="state senate district..." value={props.state_senate_district} />
              </div>
            </div>

            <div className="w-1/2 ml-1">
              <div>
                State Assembly District
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="state assembly district..." value={props.state_assembly_district}/>
              </div>
            </div>

          </div>
        </div>
        <div className="mt-2 w-full">
          <div className="flex">
            <div className="w-1/2">
              <div>
                Latitude
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="latitude..." value={props.latitude && parseFloat(props.latitude.toFixed(6), 10)} />
              </div>
            </div>

            <div className="w-1/2 ml-1">
              <div>
                Longitude
              </div>
              <div>
                <input disabled className="w-full disabled-grey" placeholder="longitude..." value={props.longitude && parseFloat(props.longitude.toFixed(6), 10)}/>
              </div>
            </div>

          </div>
        </div>
      </div>}

      </div>
    )
}

import React from "react";
import { Switch } from "react-router-dom";
import Programs from "./componentsV2/programs";
import Contracts from "./componentsV2/contracts";

import Site from "./componentsV2/site/site";
import SiteMap from "./componentsV2/map";
import Provider from "./componentsV2/provider";
import Toolbar from "./componentsV2/toolbar";
import Program from "./componentsV2/program";
import Upload from "./componentsV2/upload/upload";
import Instructions from "./componentsV2/instructions/instructions";
import AppliedRoute from "./componentsV2/AppliedRoute";

import { api } from "./api";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowIds: [],
      sites: [],
      programs: [],
      contracts: [],
      error: null,
      authorized: true
    };
  }

  componentDidMount() {
    this.getPrograms();
    this.getSites();
    this.getContracts();
  }

  getPrograms() {
    const method = "GET";
    api(this.unauthorizeUser.bind(this), "programs", method).then(res => {
      if (!res.status) {
        return this.setState({
          error: "Unable to fetch programs"
        });
      } else {
        return this.setState({
          programs: res.response.programs,
          error: null
        });
      }
    });
  }

  getSites() {
    const method = "GET";
    api(this.unauthorizeUser.bind(this), "sites", method).then(res => {
      if (!res.status) {
        return this.setState({
          error: "Unable to fetch sites"
        });
      } else {
        return this.setState({
          sites: res.response.sites,
          error: null
        });
      }
    });
  }

  getContracts() {
    const method = "GET";
    api(this.unauthorizeUser.bind(this), "contracts", method).then(res => {
      if (!res.status) {
        return this.setState({
          error: "Unable to fetch contracts"
        });
      } else {
        return this.setState({
          contracts:
            res.response.contracts &&
            res.response.contracts.filter(c => c.status === "PENDING"),
          activeContracts:
            res.response.contracts &&
            res.response.contracts.filter(c => c.status === "ACTIVE"),
          error: null
        });
      }
    });
  }

  setExpandedRowIds(expandedRowIds) {
    this.setState({
      expandedRowIds: expandedRowIds
    });
  }

  logout() {
    sessionStorage.clear();
    window.location.replace(
      `https://${process.env.REACT_APP_NYCID_ENV}/account/idpLogout.htm?`
    );
  }

  unauthorizeUser = () => {
    this.setState({ authorized: false });
  };

  getChildProps() {
    return {
      authorized: this.state.authorized,
      unauthorize: this.unauthorizeUser.bind(this),
      setExpandedRowIds: this.setExpandedRowIds.bind(this),
      expandedRowIds: this.state.expandedRowIds,
      sites: this.state.sites,
      programs: this.state.programs,
      contracts: this.state.contracts,
      activeContracts: this.state.activeContracts,
      updateContractsAndSites: this.updateContractsAndSites.bind(this)
    };
  }

  updateContractsAndSites = () => {
    this.getContracts();
    this.getSites();
  };

  errorMessage() {
    if (this.state.error && this.state.authorized) {
      return (
        <div className="text-center p-2 mt-5 mb-2 bg-primary-red-30t border border-primary-red animated fadeInUp">
          {this.state.error}
        </div>
      );
    }
  }

  render() {
    return (
      <main
        className="screen-tablet:layout-sidebar screen-tablet:flex items-stretch m-0 p-0 screen-tablet:min-h-screen"
        id="main-content"
      >
        <Toolbar
          logout={this.props.logout}
          menu={this.props.menu}
          numContracts={
            this.state.contracts && this.state.contracts.length
          }
        />
        <article className="color-light-background mx-4 mt-1 mx-auto">
          {this.errorMessage()}
          <Switch>
            <AppliedRoute
              path="/sites"
              exact
              component={Programs}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/site"
              component={Site}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/map"
              component={SiteMap}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/provider"
              component={Provider}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/program"
              component={Program}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/upload"
              component={Upload}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/instructions"
              component={Instructions}
              props={this.getChildProps()}
            />
            <AppliedRoute
              path="/"
              component={Contracts}
              props={this.getChildProps()}
            />
          </Switch>
        </article>
      </main>
    );
  }
}

export default Routes;

import React from 'react'

import Tooltip from '../tooltip';
import { siteInputClasses } from "./utils";

export default(props) => {
  return (
    <div className="text-sm leading-tight">
      <div>
        <div>
          Number
        </div>
        <div>
          <div id="contract_number" className="contract-question p-one-and-a-half border border-2 w-full disabled-grey">{props.contract_number}</div>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="w-1/3">
          <div>
            Start Date
          </div>
          <div>
            <div id="start_date" className="contract-question border border-2 w-full disabled-grey">{props.contract_start}</div>
          </div>
        </div>
        <div className="w-1/3 mx-1">
          <div>
            End Date
          </div>
          <div>
            <div id="end_date" className="contract-question border border-2 w-full disabled-grey">{props.contract_end}</div>
          </div>
        </div>
        <div className="w-1/3">
          <div>
            Fiscal Year
          </div>
          <div>
            <div id="fiscal_year" className="contract-question border border-2 w-full disabled-grey">{props.contract_fiscal_year}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import { Link } from 'react-router-dom'

const contractsWithBadge = (props) => {
  return (
    <div className="inline-flex">
      <div className="w-3/4">
        Manage Contracts
      </div>
      <div className="mt-1 w-1/4">
        <small className="text-xs color-secondary-button rounded" style={{textDecoration: "none", paddingLeft: "4px", paddingRight: "4px"}}>{props.numContracts > 99 ? "99+" : props.numContracts}</small>
      </div>
    </div>
  )
}

export default(props) => {
  return (
    <aside className="o-navigation bg-primary-navy screen-tablet-toolbar">
      <div className={`o-navigation__wrap color-mid-background screen-tablet:flex ${props.menu && 'hidden'}`}>
        <div className="o-navigation__nav">
            <nav>

              <Link className="o-navigation__link toolbar-link mt-2 pb-2 border-b-2" to="/">
                {props.numContracts > 0 ? contractsWithBadge(props) : "Manage Contracts"}
              </Link>

              <Link className="o-navigation__link toolbar-link mt-1 pb-2 border-b-2" to="/sites">Manage Sites</Link>
              <Link className="o-navigation__link toolbar-link mt-1 pb-2 border-b-2" to="/upload">Upload Sites</Link>
              <Link className="o-navigation__link toolbar-link mt-1 pb-2 border-b-2" to="/map">Map</Link>
              <Link className="o-navigation__link toolbar-link mt-1 pb-2 " to="/instructions">Instructions</Link>
              <button className="o-navigation__link btn color-secondary-button mt-4 " style={{marginLeft: "2px"}} onClick={event => props.logout()}>Logout</button>
            </nav>
        </div>
      </div>
    </aside>
  )
}

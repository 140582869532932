import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#82848a",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function CustomTooltip(args) {
  return (
      <div className="w-full inline-flex">
        <div className="mt-half">
          {args.child}
          {args.required && <span className='text-primary-red'>*</span>}
        </div>
        <div className="ml-half">
          <LightTooltip placement="top" title={
            <div className="text-sm p-1">
              {args.text}
            </div>
          }>
            <Info
              fontSize="small"
              color="action"
            />
          </LightTooltip>

        </div>
      </div>
  );
}

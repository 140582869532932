import React from 'react'
import Multiselect from '../multiselect';
import Tooltip from '../tooltip';
import { siteInputClasses } from "./utils";

export default(props) => {
  const capacityUnits = require("../../../constants/capacity_units.json");
  const languageTaxonomy = require("../../../constants/language_taxonomy.json");
  const serviceTaxonomy = require("../../../constants/service_taxonomy.json");
  const populationTaxonomy = require("../../../constants/population_taxonomy.json");
  const serviceSettingTaxonomy = require("../../../constants/service_setting_taxonomy.json");
  const bpCategory = require("../../../constants/bp_category.json");
  const bpPopulation = require("../../../constants/bp_population.json");

  return (
    <div className="text-sm">
        <fieldset>
          <label className="checkbox font-normal">
            <input id="accepts_dropins" type="checkbox" disabled={!props.active_flag} checked={props.accepts_dropins} onChange={props.handleChange('accepts_dropins')} />
            <span className={`checkbox__label ${siteInputClasses(props)}`}>
              <Tooltip
                text="Could a person show up at this site without a referral or pre-registration?"
                child="Accepts Drop-ins?"
                inline={true}
              />
            </span>
          </label>
          <label className="checkbox font-normal mt-2">
            <input id="serves_food" type="checkbox" disabled={!props.active_flag} checked={props.serves_food} onChange={props.handleChange('serves_food')} />
            <span className={`checkbox__label ${siteInputClasses(props)}`}>
              <div>
                Serves Food?
              </div>
            </span>
          </label>
        </fieldset>

        <div className="mt-1 w-full leading-tight">
          <div className="flex">
            <div className="w-1/2">
               <div className="inline-flex">
                <Tooltip
                  text="The number of people, familes, or seats specified in the contract for the location."
                  child="Capacity"
                />
              </div>
              <div>
                <input id="capacity" type="number" disabled={!props.active_flag} className={siteInputClasses(props)} placeholder="100..." value={props.capacity} onChange={props.handleChange('capacity')}/>
              </div>
            </div>
            <div className="ml-1 w-1/2">
              <div className="inline-flex">
                <div className="mt-half">
                  Capacity Units
                </div>
              </div>
              <div>
                <select id="capacity_units" disabled={!props.active_flag} className={`select border full-select ${siteInputClasses(props)}`} value={props.capacity_units == null ? "" : props.capacity_units} style={{"borderColor": "#ACAEB9", "backgroundColor": "white"}} onChange={props.handleChange('capacity_units')}>
                  <option disabled value="">choose units...</option>
                  {capacityUnits.map(cu => {
                    return <option key={cu} value={cu}>{cu + " at a time"}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="inline-flex">
              <Tooltip
                text="Which language is available for participants? Please select all that apply."
                child="Language Taxonomy"
              />
            </div>
            <div>
              <Multiselect
                disabled={!props.active_flag}
                options={languageTaxonomy}
                id={"language_taxonomy"}
                placeholder={"select language taxonomies..."}
                multi={props.language_taxonomy}
                handleChangeMulti={props.handleChangeMulti}
              />
            </div>
          </div>
          <div className="mt-2">
            <div className="inline-flex">
              <Tooltip
                text="Select all that apply."
                child="Service Taxonomy"
              />
            </div>
            <div>
              <Multiselect
                disabled={!props.active_flag}
                options={serviceTaxonomy}
                id={"service_taxonomy"}
                placeholder={"select service taxonomies..."}
                multi={props.service_taxonomy}
                handleChangeMulti={props.handleChangeMulti}
              />
            </div>
          </div>
          <div className="mt-2">
            <div className="inline-flex">
              <Tooltip
                text="Which population does your program serve? Please select all that apply."
                child="Population Taxonomy"
              />
            </div>
            <div>
              <Multiselect
                disabled={!props.active_flag}
                options={populationTaxonomy}
                id={"population_taxonomy"}
                placeholder={"select population taxonomies..."}
                multi={props.population_taxonomy}
                handleChangeMulti={props.handleChangeMulti}
              />
            </div>
          </div>
          <div className="mt-2">
            <div className="inline-flex">
              <Tooltip
                text="Select all that apply."
                child="Service Setting Taxonomy"
              />
            </div>
            <div>
              <Multiselect
                disabled={!props.active_flag}
                options={serviceSettingTaxonomy}
                id={"service_setting_taxonomy"}
                placeholder={"select service setting taxonomies..."}
                multi={props.service_setting_taxonomy}
                handleChangeMulti={props.handleChangeMulti}
              />
            </div>
          </div>
          <div className="mt-2">
            <div className="inline-flex">
              <Tooltip
                text="Select all that apply."
                child="Benefit Programs Category"
              />
            </div>
            <div>
              <Multiselect
                disabled={!props.active_flag}
                options={bpCategory}
                id={"bp_category"}
                placeholder={"select benefit program categories..."}
                multi={props.bp_category}
                handleChangeMulti={props.handleChangeMulti}
              />
            </div>
          </div>

          <div className="mt-2">
            <div className="inline-flex">
              <Tooltip
                text="Select all that apply."
                child="Benefit Programs Population"
              />
            </div>
            <div>
              <Multiselect
                disabled={!props.active_flag}
                options={bpPopulation}
                id={"bp_population"}
                placeholder={"select benefit program populations..."}
                multi={props.bp_population}
                handleChangeMulti={props.handleChangeMulti}
              />
            </div>
          </div>
        </div>
      </div>
  )
}

import * as React from 'react';

import BulkAddSite from "./bulkAddSite";
import BulkEditSite from "./bulkEditSite";

import { downloadExampleSchemaXLSX } from "./utils";

export default function Upload(args) {
  return (
    <div className="mt-3" style={{maxWidth: "1100px"}}>
      <h2 className="font-bold">Bulk Upload</h2>
      <div>
        <span className="font-bold">Instructions:</span>
        <ul className="ml-3" style={{'listStyleType': "disc"}}>
          <li>
            Please consult the {' '} 
            <button
              className="outline-none buttonLink"
              onClick={() => {
                downloadExampleSchemaXLSX()
              }}>
                bulk upload guide
            </button>
            , which includes an example <strong>Bulk Add Schema</strong>, <strong>Data Dictionary</strong>, and list of <strong>Dropdown Options</strong>.
          </li>
          <li>
            The <strong>Bulk Add Schema</strong> sheet includes sample valid bulk add rows. Please use this schema as a template for your bulk add xlsx files.
          </li>
          <li>
            The <strong>Data Dictionary</strong> sheet provides a description, data type, required status and more for each column
          </li>
          <li>
            The <strong>Dropdown Options</strong> sheet lists the allowed values for specific columns. If a column has multiple values please delimit those values with commas (e.g. language_taxonomy cell could be "Spanish, English")
          </li>
        </ul>
      </div>
      <div className="mt-2">
        <div className>
          <label className="text-xl">Bulk Edit:</label>
          <BulkEditSite
            sites={args.sites}
            accessToken={args.access_token}
            updateContractsAndSites={args.updateContractsAndSites}
          />
        </div>

        <div className="mt-4">
          <label className="text-xl">Bulk Add:</label>
          <BulkAddSite
            contracts={args.contracts.concat(args.activeContracts)}
            programs={args.programs}
            accessToken={args.access_token}
            updateContractsAndSites={args.updateContractsAndSites}
          />
        </div>
      </div>

    </div>
  )
}

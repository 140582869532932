import React from "react";
import LogoutModal from "./LogoutModal";

export function isAuthenticated(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const logoutWarningMin = (process.env.REACT_APP_LOGOUT_WARNING_MIN && parseInt(process.env.REACT_APP_LOGOUT_WARNING_MIN)) || 5;
      const logoutActionMin = (process.env.REACT_APP_ACTION_WARNING_MIN && parseInt(process.env.REACT_APP_ACTION_WARNING_MIN)) || 2;
      this.state = {
        logoutModal: false,
        fiveMin: logoutWarningMin * 60 * 1000,
        twoMin: logoutActionMin * 60 * 1000
      };
    }

    logoutURI = process.env.REACT_APP_NYCID_ENV + "account/idpLogout.htm";
    authURI =
      process.env.REACT_APP_NYCID_ENV +
      "account/api/oauth/authorize.htm?response_type=token&client_id=" +
      process.env.REACT_APP_NYCID_CLIENT_ID +
      "&redirect_uri=" +
      process.env.REACT_APP_NYCID_REDIRECT_URI;

    componentDidMount() {
      this.setPromptTimer();
    }

    componentDidUpdate() {
      this.clearTimers();
      this.setPromptTimer();
    }

    componentWillUnmount() {
      this.clearTimers();
    }

    stayLoggedIn = () => {
      this.clearTimers();
      this.setPromptTimer();
      this.toggleLogoutModal();
    };

    toggleLogoutModal = () =>
      this.setState({ logoutModal: !this.state.logoutModal });

    promptForLogout = () => {
      this.toggleLogoutModal();
      this.logoutTimer = setTimeout(() => {
        this.logout();
      }, this.state.twoMin);
    };

    setPromptTimer = () =>
      (this.logoutPromptTimer = setTimeout(() => {
        this.promptForLogout();
      }, this.state.fiveMin));

    // nycIDProperties schema:
    // {
    //    mail: emailaddress,
    //    access_token: accesstoken,
    //    givenName: firstname,
    //    sn: surname,
    //    ...other fields we're not using
    // }
    getAndStoreNYCIDProperties = () => {
      var hash = this.props.location.hash.substr(1);
      hash.split("&").forEach(item => {
        var parts = item.split("=");
        sessionStorage.setItem(parts[0], parts[1]);
      });
    };

    handleLogout = event => {
      event.preventDefault();
      this.logout();
    };

    logout = () => {
      this.clearTimers();
      sessionStorage.clear();
      window.location.replace(this.logoutURI);
    };

    clearTimers = () => {
      clearTimeout(this.logoutTimer);
      clearTimeout(this.logoutPromptTimer);
    };

    render() {
      if (this.props.location.hash) {
        this.getAndStoreNYCIDProperties();
      }
      if (!sessionStorage.access_token) {
        window.location.replace(this.authURI);
      }
      return (
        <>
          <LogoutModal
            logoutModal={this.state.logoutModal}
            toggleLogoutModal={this.toggleLogoutModal.bind(this)}
            logout={this.logout.bind(this)}
            stayLoggedIn={this.stayLoggedIn.bind(this)}
          />
          <WrappedComponent logout={this.logout} {...this.props} />
        </>
      );
    }
  };
}

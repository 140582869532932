import React from 'react';

export default(props) => {
  return (
    <React.Fragment>
      <h2>Hello!</h2>
      <p>Something has gone wrong, most likely that your ID has not been provisioned to use this application.  Please email <a href={`mailto:${process.env.REACT_APP_ADD_PROGRAM_EMAIL}?Subject=Unauthorized%20User`} target="_top">ProgramSites@nycopportunity.nyc.gov</a> for assistance.</p>
      <p>Sincerely,
      <br/>
      Site Location Data Team.
      </p>
    </React.Fragment>
  );
}

import * as React from 'react';
import dateFormat from 'dateformat'
import Paper from '@material-ui/core/Paper';
import { TreeDataState, SearchState, CustomTreeData } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table, TableTreeColumn, SearchPanel,
  Toolbar as TableToolbar
} from '@devexpress/dx-react-grid-material-ui';
import Loading from "./loading";
import { titleCase, downloadSites } from "../utils";
import { rowToSite, rowToProvider, rowToProgram } from "../adapters";
import { Link } from 'react-router-dom'

const PROGRAM_HEIGHT = 0;
const CONTRACT_HEIGHT = 1;
const SITE_HEIGHT = 2;

// 10/18/2019 abourne NOTE ON ROW IDS:
// row ids are strucutred like {{program id}}||{provider/contract id}}||{{site id}}
// this allows us to efficiently search for rows in the "updateRows" function
const getProgramRowId = (site) => {
  return `${site.program_id}||`
}

const getProviderContractRowId = (site) => {
  return `${site.program_id}|${site.provider_id + site.contract_id}|`;
}

const getSiteRowId = (site) => {
  return `${site.program_id}|${site.provider_id + site.contract_id}|${site.site_id}`
}

const getChildRows = (row, rows) => {
  const childRows = rows.filter(r => r.parent_id === (row ? row.id : 0));
  return childRows.length ? childRows : null;
};

const numChildren = (r, rows) => {
  return rows.filter(row => row.parent_id === r.id).length;
}

const sortByActiveStatus = (row1, row2) => {
    if (row1.data.active_flag) {
      return -1;
    }
    if (row2.data.active_flag) {
      return 1;
    }
    return 0;
}

class Programs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectEditSite: false,
      redirectAddSite: false,
      providerName: null,
      programName: null,
      siteName: null,
      columns: [
        { name: 'name'},
        { name: 'button'}
      ],
      rows: [],
      currentRows : [],
      tableColumnExtensions: [
        { columnName: 'name', wordWrapEnabled: true},
        { columnName: 'button', width: 160}
      ],
      loading: false,
      searchStore: {},
    };
  }

  componentDidMount() {
    if (this.props.sites[0]) {
      const rows = this.sitesToRows();
      this.setState({
        loading: false,
        rows: rows,
        currentRows: rows,
      })
    }
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.sites[0] && prevProps.sites !== this.props.sites) {
      const rows = this.sitesToRows();
      this.setState({
        loading: false,
        rows: rows,
        currentRows: rows,
      })
    }
  }

  getChildren(r) {
    return this.state.rows.filter(row => row.parent_id === r.id);
  }

  numGrandchildren(r) {
    let total = 0;
    const children = this.getChildren(r);
    children.forEach(c => {
      total = total + c.numChildren;
    })
    return total;
  }

  onExpandedRowIdsChange(expandedRowIds) {
    this.props.setExpandedRowIds(expandedRowIds);
  }

  sitesToRows() {
    let programRows = []
    let providerContractRows = []
    let siteRows = []
    this.props.sites.forEach(site => {
      const programId = getProgramRowId(site);
      if (!programRows.find( pr => pr.id === programId )) {
        programRows.push({
          id: programId,
          parent_id: 0,
          name: site.program_name,
          height: 0,
          data: site
        })
      }
      const pcId = getProviderContractRowId(site);
      if (!providerContractRows.find(pcr => pcr.id === pcId)) {
        const titleCaseName = titleCase(site.provider_name);
        providerContractRows.push({
          id: pcId,
          parent_id: programId,
          name: `${titleCaseName} (${site.contract_number})`,
          provider_name: titleCaseName,
          height: 1,
          data: site
        })
      }
      if (site.site_id) {
        const siteName = site.site_name ? site.site_name : `Unnamed Site (${site.address_1 || site.catchment_zone})`;
        siteRows.push({
          id: getSiteRowId(site),
          parent_id: pcId,
          name: `${siteName}${!site.active_flag ? " (INACTIVE)" : ""}`,
          height: 2,
          data: site
        })
      }
    })
    const rows = programRows.concat(providerContractRows).concat(siteRows).sort(sortByActiveStatus);
    const rowsWithMeta = rows.map(r => {
      return {...r,  numChildren: numChildren(r, rows)};
    })

    return rowsWithMeta;
  }


  updateRows(value) {
    const term = value.toLowerCase();
    if (!term) {
      this.setState({
        currentRows: this.state.rows
      });
    }
    let newRows = {};
    this.state.rows.forEach(thisRow => {
      if (thisRow.name.toLowerCase().includes(term) && !newRows[thisRow.id]) {
        // add this row
        newRows[thisRow.id] = true;
        const idComponents = thisRow.id.split("|");

        const programIdComponent = idComponents[0];
        const providerIdComponent = idComponents[1];

        const programId = `${programIdComponent}||`;
        const providerId = `${programIdComponent}|${providerIdComponent}|`


        // if this row is a site, add parent provider and grandparent program rows
        if (thisRow.height === SITE_HEIGHT) {
          newRows[programId] = true;
          newRows[providerId] = true;
        }

        if (thisRow.height === PROGRAM_HEIGHT) {
          // add rows with this program id component
          this.state.rows.forEach(otherRow => {
            if (programIdComponent === otherRow.id.split("|")[0]) {
              newRows[otherRow.id] = true;
            }
          })
        }

        if (thisRow.height === CONTRACT_HEIGHT) {
          // add program
          newRows[programId] = true;
          // add rows with this program id/provider id components
          this.state.rows.forEach(otherRow => {
            const otherRowIdComponents = otherRow.id.split("|");
            if ((programIdComponent === otherRowIdComponents[0]) && (providerIdComponent === otherRowIdComponents[1])) {
              newRows[otherRow.id] = true;
            }
          })
        }
      }
    });
    this.setState({
      currentRows: this.state.rows.filter(r => !!newRows[r.id])
    });
  }

  errorMessage() {
    if (this.state.error) {
      return (
        <div className="text-center p-2 mt-5 mb-2 bg-primary-red-30t border border-primary-red animated fadeInUp">
          {this.state.error}
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="mt-4">
          <Loading/>
        </div>
      );
    }
    const columns = this.state.columns;
    const rows = this.state.currentRows;

    const TreeCell = (props) => {
      let row = props.row;
      let height = row.height;
      let path="/site"
      let state={};

      if (height === SITE_HEIGHT) {
        state={
          newSite: false,
          site: rowToSite(row),
          program: rowToProgram(row),
          provider: rowToProvider(row),
          contract: {
            number: row.data.contract_number,
            fiscal_year: row.data.fiscal_year,
            start: dateFormat(row.data.start_date, "mm/dd/yyyy"),
            end: dateFormat(row.data.end_date, "mm/dd/yyyy")
          }
        }
      }
      if (height === CONTRACT_HEIGHT) {
        path="/provider";
        state={
          provider: rowToProvider(row)
        };
      }
      if (height === PROGRAM_HEIGHT) {
        path="/program";
        state={
          program: rowToProgram(row)
        };
      }
      return (
        <TableTreeColumn.Cell {...props}>
          {props.children.slice(0, props.children.length-1)}
          <div className="inline-flex">
          <Link to={{
            pathname:path,
            state: state
          }}>
            <div className="font-bold">
              {props.value}
            </div>
          </Link>
          </div>
        </TableTreeColumn.Cell>
      );
    }

    const Cell = (props) => {
      const { column, row } = props;
      const rowHeight = row.height;
      if (column.name === 'button' && rowHeight === PROGRAM_HEIGHT) {

        const numGrandchildren = this.numGrandchildren(row);

        return (
          <Table.Cell {...props}>
            <div className="mr-2 inline-flex">
              <div className="mr-2">
                <span className="font-bold">Num Sites:</span> {numGrandchildren}
              </div>
            </div>
          </Table.Cell>
        )
      }
      if (column.name === 'button' && rowHeight === CONTRACT_HEIGHT) {
        return (
          <Table.Cell {...props}>
          <div className="mr-2 inline-flex">
            <Link to={{
              pathname: "/site",
              state: {
                newSite: true,
                program: rowToProgram(row),
                provider: rowToProvider(row),
                site: {},
                contract: {
                  number: row.data.contract_number,
                  fiscal_year: row.data.fiscal_year,
                  start: dateFormat(row.data.start_date, "mm/dd/yyyy"),
                  end: dateFormat(row.data.end_date, "mm/dd/yyyy")
                }
              }
            }} >
              <button className="mr-1 px-2 outline-none border btn color-primary-button">Add Sites</button>
            </Link>
          </div>
          </Table.Cell>
        );
      }
      return <Table.Cell {...props} />;
    };

    return (
      <div>
        <div className="flex mb-2">
          <div className="w-1/2">
            <h2 className="font-bold">Grouped Sites</h2>
          </div>
          <div className="w-1/2">
            <div className="float-right mt-5">
              <button className="o-navigation__link outline-none btn color-primary-button mt-2" onClick={() => {downloadSites(this.props.sites)}}>Download</button>
            </div>
          </div>
        </div>
        {this.errorMessage()}

        
        <Paper className="border">
          <Grid
            rows={rows}
            columns={columns}
            getRowId={(r) => r.id}
          >
            <TreeDataState
              expandedRowIds={this.props.expandedRowIds}
              onExpandedRowIdsChange={(expandedRowIds) => this.props.setExpandedRowIds(expandedRowIds)}
            />
            <SearchState onValueChange={(value) => {
              this.updateRows(value)
            }} />

            <CustomTreeData
              getChildRows={getChildRows}
            />


            <Table
              cellComponent={Cell}
              columnExtensions={this.state.tableColumnExtensions}
            />

            <TableTreeColumn
              for="name"
              cellComponent={TreeCell}
            />
            <TableToolbar />
            <SearchPanel />

          </Grid>
        </Paper>
      </div>
    );
  }
}

export default Programs;
